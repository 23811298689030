import React, { useCallback, useRef } from 'react';

import { useTranslation } from 'react-i18next';
import { Divider } from 'semantic';
import { Link } from 'react-router-dom';

import { ListHeader, Breadcrumbs, Search } from 'components';

import { request } from 'utils/api';
import Table from './Table';
import {
  backgroundJobsFilterMapping,
  BackgroundJobsListFiltersHeader,
} from './FilterHeader';
import { useFilterContext } from 'components/search/Context';
import {
  CHARGING_STATIONS_FE_PATH,
  EVSE_BULK_ACTIONS_SEARCH_BE_PATH,
} from '../utils';

export default function EvseControllersBackgroundJobsList() {
  const { t } = useTranslation();
  const searchRef = useRef();

  const onDataNeeded = useCallback(async (filters) => {
    const result: { data: any[] } = await request({
      method: 'POST',
      path: EVSE_BULK_ACTIONS_SEARCH_BE_PATH,
      body: filters,
    });

    return result;
  }, []);

  return (
    <Search.Provider
      ref={searchRef}
      onDataNeeded={onDataNeeded}
      filterMapping={backgroundJobsFilterMapping}
      limit={100}>
      {() => {
        return (
          <>
            <Breadcrumbs
              path={[
                <Link to={CHARGING_STATIONS_FE_PATH}>
                  {t(
                    'evseControllersBackgroundJobs.breadcrumbs',
                    'Charging Stations'
                  )}
                </Link>,
              ]}
              active={t(
                'evseControllersBackgroundJobs.header',
                'Background Jobs'
              )}
            />
            <div style={{ marginTop: 0, marginBottom: 20 }}>
              <ListHeader
                title={t(
                  'evseControllersBackgroundJobs.title',
                  'Background Jobs'
                )}
              />
            </div>

            <div>
              <BackgroundJobsListFiltersHeader />
              <Search.Status noResults={<NoResults />} />
              <Table />
            </div>
            <Divider hidden />
            <div
              style={{
                textAlign: 'center',
              }}>
              <Search.Pagination />
            </div>
          </>
        );
      }}
    </Search.Provider>
  );
}

const NoResults = () => {
  const { t } = useTranslation();
  const filterContext = useFilterContext();

  return (
    <div>
      {t('common.noResults', 'No Results')}
      {Object.keys(filterContext.filters).length > 0 && (
        <button
          className="borderless-blue-button"
          onClick={() => {
            filterContext.setFilters({});
            filterContext.reload();
          }}>
          {t('common.clearFilters', 'Clear All Filters')}
        </button>
      )}
    </div>
  );
};
