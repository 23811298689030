export type EvseController = {
  configuration?: any;
  tariffProfileId?: string;
  optOutOfScanToPay?: boolean;
  smartCharging?: {
    method?: string;
    paired?: boolean;
  };
  setupProgress?: {
    state?: string;
    currentStep?: number;
  };
  associatedAccounts?: {
    readAccess?: any[];
    writeAccess?: any[];
  };
  accessGroupIds?: string[];
  startCostInvoiceIds?: string[];
  numConnectors?: number;
  enablePublicFreeCharging?: boolean;
  enablePublicCharging?: boolean;
  disableRoamingChargeForProviders?: boolean;
  disableRoamingChargePartyIds?: string[];
  enableTariffProfileOverride?: boolean;
  isDisabled?: boolean;
  ocppAuthorizationMethod?: string;
  connectivityState?: EvseControllerConnectivityState;
  rebootRequired?: boolean;
  setupInProgress?: boolean;
  syncOcpiCredentialIds?: string[];
  ocppIdentity?: string;
  ocppFtpPassword?: string;
  ocppAuthorizationKey?: string;
  providerId?: string;
  numericIdentity?: number;
  createdAt?: string;
  accessGroupIdsHistory?: any[];
  connectors?: EvseControllerConnector[];
  authorizationInfo?: any;
  connectorsHistory?: {
    _id?: string;
    connectorObjectId?: string;
    createdAt?: string;
    id?: string;
  }[];
  costSettings?: EvseControllerCostSettings[];
  bootInfoSerialNumbers?: {
    _id?: string;
    serialNumber?: string;
    lastBootedAt?: string;
    id?: string;
  }[];
  externalSync?: any[];
  updatedAt?: string;
  connectivityStateUpdatedAt?: string;
  coordinatorConnectedAt?: string;
  coordinatorDisconnectedAt?: string;
  coordinatorStatus?: {
    state?: string;
    connectionInfo?: {
      remoteAddress?: string;
      protocol?: string;
      host?: string;
    };
    identifier?: string;
    websocketId?: number;
  };
  lastConnectivityMode?: string;
  bootInfo?: {
    chargePointVendor?: string;
    chargePointModel?: string;
    chargePointSerialNumber?: any;
    chargeBoxSerialNumber?: any;
    firmwareVersion?: string;
    iccid?: any;
    imsi?: any;
    options?: {
      protocol?: string;
    };
  };
  heartbeatReceivedAt?: string;
  lastBootedAt?: string;
  serialNumber?: string;
  simpleSerialNumber?: string;
  tokenAuthorizeReceivedAt?: string;
  connectorStatus?: {
    [key: number]: ConnectorNode;
  };
  evseIdRef?: {
    spotOperatorId?: string;
    idType?: string;
    providerId?: string;
    powerOutletId?: string;
    createdAt?: string;
    updatedAt?: string;
    assignedAt?: string;
    evseControllerId?: string;
    countryCode?: string;
    identifier?: string;
    url?: string;
  };
  evseId?: string;
  locationId?: string;
  normalizedEvseId?: string;
  accountId?: string;
  billingPlanId?: string;
  maintenanceAccountId?: string;
  activatedAt?: string;
  timezone?: string;
  energyDeliveryArea?: string;
  ocppProtocolVersion?: string;
  id?: string;
  account?: {
    id?: string;
    name?: string;
    billing?: {
      sepaAccepted?: boolean;
      sepaAcceptedAt?: string;
    };
    contact?: {
      gender?: string;
      phoneCountryCode?: string;
      firstName?: string;
      lastName?: string;
      phoneNo?: string;
      email?: string;
    };
    associatedAccounts?: {
      readAccess?: any[];
      writeAccess?: any[];
    };
    paymentTerm?: number;
    platformFeatures?: any[];
    enableApiAccess?: boolean;
    notifyEVSEIssues?: boolean;
    notifyEVSEIssuesRecipient?: string;
    defaultLangCode?: string;
    vatNo?: any;
    type?: string;
    billingCurrency?: string;
    providerId?: string;
    createdAt?: string;
    updatedAt?: string;
    nonLegacy?: boolean;
    numericReferenceCode?: number;
  };
  provider?: {
    id?: string;
    supportedSmartChargingProviders?: string[];
    individualFees?: number;
    organisationFees?: number;
    isNewsletterFeatureEnabled?: boolean;
    activeCurrencies?: string[];
    name?: string;
    slug?: string;
    tosUrl?: string;
    companyInfo?: string;
    enableInvoicing?: boolean;
    enableSelfSignup?: boolean;
    enableMspSessionDeletion?: boolean;
    enableWebAnalytics?: boolean;
    supportedLanguages?: string[];
    enableChargeAtHome?: boolean;
    enableChargeStationPairChargeCard?: boolean;
    enabledChargeProducts?: string[];
    enableAccountTiers?: boolean;
    optOutOfScanToPay?: boolean;
    logoUrl?: string;
    invertedLogoUrl?: string;
    faviconUrl?: string;
    primaryColorHex?: string;
    primaryColorDarkHex?: string;
    address?: string;
    supportEmail?: string;
    supportPhoneNo?: string;
    supportUrl?: string;
    sandboxSettings?: {
      _id?: string;
      defaultDeveloperAccountId?: string;
      defaultDeveloperUserId?: string;
      cpoAccountId?: string;
      mspAccountId?: string;
    };
    supportedServices?: string[];
    supportedPaymentMethods?: string[];
  };
  maintenanceAccount?: {
    id?: string;
    name?: string;
    billing?: {
      sepaAccepted?: boolean;
      sepaAcceptedAt?: string;
    };
    contact?: {
      gender?: string;
      phoneCountryCode?: string;
      firstName?: string;
      lastName?: string;
      phoneNo?: string;
      email?: string;
    };
    associatedAccounts?: {
      readAccess?: any[];
      writeAccess?: any[];
    };
    paymentTerm?: number;
    platformFeatures?: any[];
    enableApiAccess?: boolean;
    notifyEVSEIssues?: boolean;
    notifyEVSEIssuesRecipient?: string;
    defaultLangCode?: string;
    vatNo?: any;
    type?: string;
    billingCurrency?: string;
    providerId?: string;
    createdAt?: string;
    updatedAt?: string;
    nonLegacy?: boolean;
    numericReferenceCode?: number;
  };
  billingPlan?: {
    id?: string;
    priority?: number;
    name?: string;
    details?: {
      [key: string]: {
        features?: string[];
        name?: string;
        descriptionLong?: string;
      };
    };
    providerContext?: string;
    type?: string;
    isPublic?: boolean;
    isPrimary?: boolean;
    tags?: string[];
    billingPeriod?: string;
    pricePerCard?: number;
    pricePerSession?: number;
    pricePerDevice?: number;
    pricePerPeriod?: number;
    providerId?: string;
    createdAt?: string;
    updatedAt?: string;
    prices?: {
      perPeriod?: number;
      perCard?: number;
      perDevice?: number;
      perSession?: number;
      _id?: string;
      currency?: string;
    }[];
  };
  location?: {
    id?: string;
    geoLocation?: {
      type?: string;
      coordinates?: number[];
    };
    associatedAccounts?: {
      readAccess?: any[];
      writeAccess?: any[];
    };
    providerContext?: string;
    accessPolicy?: AccessPolicy;
    status?: string;
    evseIds?: string[];
    hasTariffInfo?: boolean;
    connectorTypes?: string[];
    evses?: {
      uid?: string;
      evseControllerId?: string;
      ocppIdentity?: string;
      evse_id?: string;
      normalizedEvseId?: string;
      status?: string;
      isDisabled?: boolean;
      enablePublicCharging?: boolean;
      authorizationTypes?: string[];
      setupFinished?: boolean;
      connectors?: {
        uid?: string | null;
        id?: string | null;
        standard?: string | null;
        format?: string | null;
        power_type: string | null;
        voltage?: number | null;
        amperage?: number | null;
        last_updated?: string | null;
        evseId?: string;
      }[];
      deleted?: boolean;
      last_updated?: string;
    }[];
    facilities?: any[];
    syncOcpiCredentialIds?: string[];
    isPublished?: boolean;
    publishingMode?: string;
    accountId?: string;
    userId?: string;
    providerId?: string;
    source?: string;
    country?: string;
    name?: string;
    address?: string;
    postal_code?: string;
    city?: string;
    creditBillingTarget?: any[];
    externalSync?: {
      currentDirtyFlags?: string[];
      _id?: string;
      name?: string;
      states?: {
        endpoint?: string;
        status?: string;
        totalRuns?: number;
        _id?: string;
        nextTryAfter?: any;
      }[];
    }[];
    createdAt?: string;
    updatedAt?: string;
    numericIdentity?: number;
    last_updated?: string;
    billingPolicy?: {
      usageReimbursement?: {
        type?: string;
        percentage?: number;
        entity?: {
          type?: string;
          userId?: string;
        };
      }[];
      subscriptionBilling?: {
        percentage?: number;
        entity?: {
          type?: string;
          accountId?: string;
        };
      }[];
    };
    infraProviderId?: string;
    maxPower?: number;
    powerType?: string;
    energyDeliveryArea?: string;
  };
  pairedChargeCardId?: string;
  pairedChargeCard?: {
    id: string;
    accountId: string;
  };
  accessGroups?: any[];
  ocppChargingStationId?: string;
};

export type EvseControllerCostSettings = {
  enableEnergyMarketTracking?: boolean;
  pricePerSession?: number;
  pricePerHour?: number;
  pricePerKwh?: number;
  _id?: string;
  connectorId?: number;
  surchargePerKwh?: number;
  currency?: string;
  id?: string;
};

export type EvseControllerConnector = {
  _id?: string;
  connectorId?: number;
  evseId?: string;
  id?: string;
  powerType?: string;
  standard?: string;
};

export enum EvseControllerConnectivityState {
  Connected = 'connected',
  MaybeConnected = 'maybe-connected',
  Disconnected = 'disconnected',
  AccessDenied = 'access-denied',
  Unknown = 'unknown',
  PendingFirstConnection = 'pending-first-connection',
}

type ConnectorNode = {
  connectorId?: number;
  errorCode?: string;
  status?: string;
  ocppConnectorId?: number;
  ocppEvseId?: number;
};

type AccessPolicy =
  | 'employeeReimburse'
  | 'businessReimburse'
  | 'communityReimburse'
  | 'noReimburse';
