import React, { useCallback, useRef } from 'react';
import { Divider, Dropdown, Button } from 'semantic';

import { currentUserCanAccess, canAccessProviderEndpoint } from 'utils/roles';
import ImportEvseCommands from 'components/modals/ImportEvseCommands';
import Table from './Table';

import { Link, useHistory } from 'react-router-dom';
import { request } from 'utils/api';

import { useUser } from 'contexts/user';
import { Breadcrumbs, ListHeader, Search } from 'components';
import FeatureFlag from 'components/FeatureFlag';

import { useTranslation } from 'react-i18next';
import { evseFilterMapping, EvseListFiltersHeader } from './FilterHeader';
import { useFilterContext } from 'components/search/Context';
import Create from 'screens/EvseControllers/Create';
import './FilterHeader.less';
import { decodeStringForFilter } from 'utils/filters-header';

export default function EvseControllersList() {
  const { t } = useTranslation();
  const searchRef = useRef();
  const history = useHistory();
  const { user, provider } = useUser();

  const onDataNeeded = useCallback(async (filters) => {
    if (filters.setupStates) {
      /** @see {@link ./FilterHeader.tsx#setupStateOptions} for notes on this **/
      if (filters.setupStates.includes('IN-PROGRESS')) {
        filters.setupInProgress = true;
        filters.setupStates = filters.setupStates.filter(
          (e) => e !== 'IN-PROGRESS'
        );
      }

      /** @see {@link ./FilterHeader.tsx#setupStateOptions} for notes on this **/
      if (filters.setupStates.includes('READY-FOR-ACTIVATION')) {
        filters.readyForActivation = true;
        filters.setupStates = filters.setupStates.filter(
          (e) => e !== 'READY-FOR-ACTIVATION'
        );
      }

      // Cleaning the request up.
      if (filters.setupStates.length === 0) {
        filters.setupStates = undefined;
      } else {
        // Due to how SearchProvider works _ is considered an array separator, so I need to convert the array back to its _ form to suite the API.
        filters.setupStates = filters.setupStates.map((state) =>
          state.replaceAll('-', '_')
        );
      }
    }

    if (filters.ocppVersions) {
      filters.ocppVersions = filters.ocppVersions.map(decodeStringForFilter);
    }

    if (filters.vendors) {
      filters.vendors = filters.vendors.map(decodeStringForFilter);
    }

    if (filters.firmwareVersions) {
      filters.firmwareVersions = filters.firmwareVersions.map(
        decodeStringForFilter
      );
    }

    return request({
      method: 'POST',
      path: `/1/evse-controllers/search${
        filters.format !== 'csv' ? '/fast' : ''
      }`,
      body: filters,
    });
  }, []);

  const createEvseController = useCallback(async (params) => {
    const { data } = await request({
      method: 'POST',
      path: '/1/evse-controllers',
      body: params,
    });

    history.push(`/charging-stations/${data?.id}`);
  }, []);

  const writeAccess = currentUserCanAccess('accounts', 'write');
  const evseControllersProviderWriteAccess = canAccessProviderEndpoint(
    user,
    provider.id,
    'evseControllers',
    'write'
  );

  return (
    <Search.Provider
      ref={searchRef}
      onDataNeeded={onDataNeeded}
      filterMapping={evseFilterMapping}
      limit={100}>
      {() => {
        return (
          <>
            <Breadcrumbs
              active={t('evseControllers.header', 'Charging Stations')}
            />

            <ListHeader
              title={t('evseControllers.header', 'Charging Stations')}>
              <Search.Export content="Export All" filename="evseControllers" />

              <Search.Export
                content="Export All Connectors"
                filename="connectors"
                body={{ exportConnectorsOnly: true }}
              />

              <Dropdown button basic text="Actions" direction="left">
                <Dropdown.Menu>
                  <ImportEvseCommands
                    trigger={
                      <Dropdown.Item
                        basic
                        style={{ marginTop: '1px' }}
                        content="Bulk Commands"
                        icon="terminal"
                        disabled={!writeAccess}
                      />
                    }
                  />
                  <Dropdown.Item
                    basic
                    as={Link}
                    to="/charging-stations/issues"
                    style={{ marginTop: '1px' }}
                    content="Detected Issues"
                    icon="crosshairs"
                  />
                  <FeatureFlag feature="charging_station_draft_creation">
                    {evseControllersProviderWriteAccess && (
                      <Create
                        size="small"
                        submit={(params) => createEvseController(params)}
                        trigger={
                          <Dropdown.Item
                            basic
                            content={t(
                              'evseControllers.createSelection',
                              'New Station'
                            )}
                            icon="plus"
                          />
                        }
                      />
                    )}
                  </FeatureFlag>
                </Dropdown.Menu>
              </Dropdown>
            </ListHeader>

            <EvseListFiltersHeader />

            <Search.Status noResults={<NoResults />} />
            <Table />
            <Divider hidden />
            <div
              style={{
                textAlign: 'center',
              }}>
              <Search.Pagination />
            </div>
          </>
        );
      }}
    </Search.Provider>
  );
}

const NoResults = () => {
  const { t } = useTranslation();
  const filterContext = useFilterContext();

  return (
    <div>
      {t('common.noResults', 'No Results')}
      {Object.keys(filterContext.filters).length > 0 && (
        <button
          className="borderless-blue-button"
          onClick={() => {
            filterContext.setFilters({});
            filterContext.reload();
          }}>
          {t('common.clearFilters', 'Clear All Filters')}
        </button>
      )}
    </div>
  );
};
