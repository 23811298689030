import React from 'react';
import { Divider, Grid, Segment } from 'semantic';
import { AggregateTimeSeries, SeriesChart } from 'react-tectonic';

import {
  getColors,
  mergeFilters,
  sessionsExclusionFilter,
} from 'utils/visualizations';
import TermsDonutChart from 'components/analytics/TermsDonutChart';
import TermsTable from 'components/analytics/TermsTable';
import { formatEuro } from 'utils/formatting';
import { Link } from 'react-router-dom';
import { useUser } from 'contexts/user';
import { useTranslation } from 'react-i18next';

export default function MspFinancials({ baseFilter = {} }) {
  const { provider } = useUser();
  const colorHex = `#${provider.primaryColorHex}`;
  const { t } = useTranslation();

  const colors = getColors(colorHex);

  return (
    <div>
      <Divider hidden />
      <Divider hidden />

      <AggregateTimeSeries
        collection="sessions"
        operation="sum"
        field="externalCalculatedPrice"
        filter={mergeFilters(baseFilter, sessionsExclusionFilter)}>
        <SeriesChart
          title={t('analyticsSessions.revenue', 'Revenue')}
          titleAlign="center"
          chartType="area"
          height={350}
          valueFieldLabel="Transaction Costs + Fees"
          valueFormatter={(item) => formatEuro(Math.round(item), 0, true)}
          color={colorHex}
        />
      </AggregateTimeSeries>

      <Divider hidden />
      <Divider hidden />

      <Grid>
        <Grid.Row>
          <Grid.Column computer={8} mobile={16}>
            <Segment basic>
              <TermsDonutChart
                colors={colors}
                collection="cards"
                aggField="status"
                title={t('analyticsCards.cardStatus', 'Card Status')}
                filter={baseFilter}
                labels={{
                  active: t('anlytics.cardStatusActive', 'Active'),
                  sent: t('anlytics.cardStatusSent', 'Sent'),
                  pending: t('anlytics.cardStatusPending', 'Pending'),
                  disabled: t('anlytics.cardStatusDisabled', 'Disabled'),
                }}
              />
            </Segment>
          </Grid.Column>
          <Grid.Column computer={8} mobile={16}>
            <Segment basic>
              <TermsDonutChart
                colors={colors}
                collection="cards"
                aggField="type"
                title={t('analyticsCards.cardType', 'Card Type')}
                filter={baseFilter}
              />
            </Segment>
          </Grid.Column>
        </Grid.Row>
      </Grid>

      <Divider hidden />
      <Divider hidden />

      <Grid>
        <Grid.Row>
          <Grid.Column computer={8} mobile={16}>
            <Segment basic>
              <TermsTable
                collection="sessions"
                aggField="accountId"
                operation="sum"
                field="externalCalculatedPrice"
                title={t(
                  'analyticsSessions.topAccountsRevenue',
                  'Top Accounts by Revenue'
                )}
                filter={mergeFilters(
                  {
                    terms: [
                      { excluded: false },
                      { status: ['accepted', 'approved'] },
                    ],
                  },
                  mergeFilters(baseFilter, sessionsExclusionFilter)
                )}
                valueField="value"
                valueFieldName="Revenue"
                valueFormatter={(item) => formatEuro(Math.round(item), 0, true)}
                fetchReference="accounts"
                referenceLabelFormatter={(item) => (
                  <Link to={`/accounts/${item.id}`}>{item.name}</Link>
                )}
                termsSize={15}
              />
            </Segment>
          </Grid.Column>
          <Grid.Column computer={8} mobile={16}>
            <Segment basic>
              <TermsTable
                collection="sessions"
                aggField="billingPlanId"
                operation="sum"
                field="externalCalculatedPrice"
                title="Billing Plan by Revenue"
                filter={baseFilter}
                valueField="value"
                valueFieldName="Revenue"
                valueFormatter={(item) => formatEuro(Math.round(item), 0, true)}
                fetchReference="billingplans"
                referenceLabelFormatter={(item) => item.details.en.name}
                termsSize={15}
              />
            </Segment>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </div>
  );
}
