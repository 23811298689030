import React from 'react';
import { Divider, Grid, Segment } from 'semantic';
import { Aggregate, AggregateTerms, MultiSeriesChart } from 'react-tectonic';

import { defaultColors, getColors } from 'utils/visualizations';
import TermsDonutChart from 'components/analytics/TermsDonutChart';
import TermsTable from 'components/analytics/TermsTable';
import { useUser } from 'contexts/user';

export default function MspLocations({ baseFilter = {} }) {
  const { provider } = useUser();
  const colorHex = `#${provider.primaryColorHex}`;
  const colors = getColors(colorHex);

  return (
    <div>
      <Divider hidden />
      <Divider hidden />

      <Grid>
        <Grid.Row>
          <Grid.Column computer={8} mobile={16}>
            <Segment basic>
              <TermsDonutChart
                collection="sessions"
                aggField="currency"
                operation="count"
                title="Currencies"
                filter={baseFilter}
                termsSize={15}
                colors={colors}
              />
            </Segment>
          </Grid.Column>
          <Grid.Column computer={8} mobile={16}>
            <Segment basic>
              <TermsTable
                collection="sessions"
                aggField="location.countryCode"
                operation="count"
                title="Countries"
                valueField="count"
                valueFieldName="Sessions"
                filter={baseFilter}
                termsSize={15}
              />
            </Segment>
          </Grid.Column>
        </Grid.Row>
      </Grid>

      <Divider hidden />
      <Divider hidden />

      <Grid>
        <Grid.Row>
          <Grid.Column computer={8} mobile={16}>
            <Segment basic>
              <TermsTable
                collection="sessions"
                aggField="infraProviderId"
                title="Operators"
                operation="count"
                valueField="count"
                valueFieldName="Sessions"
                labelFormatter={(v) => v.toString().toUpperCase()}
                filter={baseFilter}
                termsSize={15}
              />
            </Segment>
          </Grid.Column>
          <Grid.Column computer={8} mobile={16}>
            <Segment basic>
              <TermsDonutChart
                collection="sessions"
                aggField="importSource"
                title="Source"
                valueFieldName="Source"
                filter={baseFilter}
                termsSize={15}
                colors={colors}
              />
            </Segment>
          </Grid.Column>
        </Grid.Row>
      </Grid>

      <Divider hidden />
      <Divider hidden />

      <AggregateTerms
        collection="sessions"
        aggField="infraProviderId"
        operation="count"
        filter={baseFilter}
        termsSize={10}>
        {({ data }) => {
          return (
            <Aggregate
              type="time-series"
              collection="sessions"
              requests={data.map((term) => {
                return {
                  operation: 'count',
                  dateField: 'createdAt',
                  filter: {
                    terms: [{ infraProviderId: term.key }],
                  },
                };
              })}>
              {({ data: chartData, ...props }) => {
                /*
                  (chartData[0] || []).forEach((item, i) => {
                    let total = 0;
                    for (let j = 0; chartData.length > j; j++) {
                      total += chartData[j][i].count || 0;
                    }
                    for (let j = 0; chartData.length > j; j++) {
                      chartData[j][i].total = total;
                      chartData[j][i].percentage = Math.round(
                        (chartData[j][i].count / total) * 100
                      );
                    }
                  });*/
                return (
                  <MultiSeriesChart
                    data={chartData}
                    title={'Sessions by Operator'}
                    colors={colors}
                    titleAlign="center"
                    height={350}
                    chartType="area"
                    stacked
                    valueField="count"
                    labels={data.map((term) => term.key)}
                    {...props}
                  />
                );
              }}
            </Aggregate>
          );
        }}
      </AggregateTerms>

      <Divider hidden />
      <Divider hidden />
    </div>
  );
}
