import React, { useState, useEffect, useRef } from 'react';
import { Form, Modal, Button, Header, Divider } from 'semantic';
import { Formik, FormikProps } from 'formik';
import { useTranslation } from 'react-i18next';
import { request } from 'utils/api';
import { BillingInfoFormikForm } from 'components/BillingInfo/formik-form';
import InputField from 'components/form-fields/formik/InputField';
import SearchDropdownField from 'components/form-fields/formik/SearchDropdown';
import RadioButtonField from 'components/form-fields/formik/RadioButtonField';
import { Space } from '../../../components/Layout/helpers';
import {
  useInviteEmployeeFormInitialState,
  useInviteEmployeeFormValidationSchema,
  InviteEmployeeFormValues,
} from './formData';
import { useUser } from 'contexts/user';
import { useAccount } from 'contexts/account';
import { useFeatures } from 'contexts/features';
import { useSubmitInviteEmployee } from './api';
import {
  InviteEmployeeErrorMessage,
  useInviteEmployeeErrorFormatter,
} from './errors';

export default function InviteEmployee({
  initialValues = {
    requestUserGender: 'female',
  },
  trigger,
  isOpen,
  onSuccess = () => {},
  onClose = () => {},
}: any = {}) {
  const [open, setOpen] = useState(!!isOpen);
  const { t } = useTranslation();
  const { hasFeature, isReady: isFeatureReady } = useFeatures();
  const isBicCollectionEnabled = hasFeature('billing_bic_collection');

  const { user } = useUser();
  const { account, getAccount } = useAccount();

  const [submitted, setSubmitted] = useState(false);
  const isUpdate = Boolean(initialValues.id);

  const formRef = useRef<FormikProps<InviteEmployeeFormValues> | null>(null);
  const formInitialState = useInviteEmployeeFormInitialState();

  const showAccountBilling = isFeatureReady && formInitialState.showBillingInfo;

  const errorFormatter = useInviteEmployeeErrorFormatter();
  const formValidationSchema = useInviteEmployeeFormValidationSchema(
    showAccountBilling,
    isBicCollectionEnabled
  );

  useEffect(() => {
    setOpen(!!isOpen);
  }, [isOpen]);

  const onDataNeeded = async (body: any) =>
    request({
      path: '/1/users/search/fast',
      method: 'POST',
      body: {
        ...(body?.name && { searchPhrase: body.name }),
        accountId: user?.accountId,
      },
    }).then(({ data }) => {
      return {
        data: [
          {
            name: t(
              'evseInviteModal.forSomebodyElse',
              'For somebody else (new)'
            ),
            id: 'new',
          },
          ...data.filter((obj: any) => obj.id !== user.id),
        ],
      };
    });

  const submitInviteEmployee = useSubmitInviteEmployee(showAccountBilling, {
    onSuccess: async () => {
      if (onSuccess) {
        onSuccess();
      } else {
        setOpen(false);
      }
      await getAccount?.();
    },
    onError: (error) => {
      error.innerError.details?.forEach((detail) =>
        errorFormatter(detail, formRef.current)
      );
    },
  });

  const onSubmit = (values: InviteEmployeeFormValues) => {
    submitInviteEmployee.mutate(values);
    setSubmitted(true);
  };

  return (
    <Modal
      size="small"
      closeIcon
      trigger={trigger}
      onClose={() => {
        setOpen(false);
        onClose();
      }}
      onOpen={() => setOpen(true)}
      open={open}>
      <Modal.Header>
        {t('evseInviteModal.title', 'Invite employee')}
      </Modal.Header>
      <Modal.Content>
        <p>
          {t(
            'evseInviteModal.description',
            `An invitation e-mail will be send to the employee for registration of
              the charging station. After completing the registration, the charging
              station will be visible in your account and the subscription will be
              billed to your company. The reimbursement of the charge sessions will
              be paid to the employee every month.`
          )}
        </p>
        <Formik
          innerRef={formRef}
          onSubmit={onSubmit}
          initialValues={{
            ...formInitialState.formData,
          }}
          validationSchema={formValidationSchema}>
          {({ values, handleSubmit, isSubmitting }) => {
            return (
              <Form onSubmit={handleSubmit}>
                {submitInviteEmployee.isError && (
                  <InviteEmployeeErrorMessage
                    error={submitInviteEmployee.error}
                    onDismiss={() => submitInviteEmployee.reset()}
                  />
                )}
                <InputField
                  label={t('evseInviteModal.pricePerKwh')}
                  name="pricePerKwh"
                  required
                  type="number"
                  step={0.01}
                  wrapperStyle={{ marginBottom: '15px' }}
                />

                <SearchDropdownField
                  name="requestUserId"
                  label={t('evseInviteModal.employee', 'Employee')}
                  required
                  objectMode={false}
                  onDataNeeded={onDataNeeded}
                />

                {values.requestUserId === 'new' && (
                  <>
                    <Form.Field required>
                      <label>{t('formLabel.salutation', 'Salutation')}</label>
                      <Form.Group style={{ marginBottom: '15px' }}>
                        <RadioButtonField
                          name="requestUserGender"
                          label={t('options.salutationMadam', 'Madam')}
                          id="female"
                        />
                        <RadioButtonField
                          name="requestUserGender"
                          label={t('options.salutationSir', 'Sir')}
                          id="male"
                        />
                      </Form.Group>
                    </Form.Field>
                    <InputField
                      name="requestUserFirstName"
                      label={t('formLabel.firstName', 'First Name')}
                      required
                      wrapperStyle={{ marginBottom: '15px' }}
                    />
                    <InputField
                      name="requestUserLastName"
                      label={t('formLabel.lastName', 'Last Name')}
                      required
                      wrapperStyle={{ marginBottom: '15px' }}
                    />
                    <InputField
                      name="requestUserEmail"
                      label={t('formLabel.email', 'Email Address')}
                      required
                      wrapperStyle={{ marginBottom: '15px' }}
                    />
                  </>
                )}
                {showAccountBilling && isFeatureReady && (
                  <>
                    <Header
                      textAlign="left"
                      as="h3"
                      style={{ marginTop: 30, marginBottom: -15 }}>
                      {t(
                        'addCardModal.billingDetailsHeader',
                        'Billing Details'
                      )}
                    </Header>
                    <BillingInfoFormikForm
                      enableBicCollection={isBicCollectionEnabled}
                      accountType={account?.type || 'individual'}
                      enableCountryCodeInput={
                        !formInitialState.formData.billing.countryCode
                      }
                      objectPath="billing"
                    />
                  </>
                )}
                <Divider />
                <Button
                  type="submit"
                  primary
                  floated="right"
                  loading={isSubmitting && !submitted}
                  disabled={isSubmitting && !submitted}
                  onClick={handleSubmit}
                  content={
                    isUpdate
                      ? t('button.update', 'Update')
                      : t('button.create', 'Create')
                  }
                />
                <Space direction="vertical" size={25} />
              </Form>
            );
          }}
        </Formik>
      </Modal.Content>
    </Modal>
  );
}
