import React from 'react';
import { Divider, Grid, Segment, Statistic } from 'semantic';
import { useTranslation } from 'react-i18next';
import {
  AggregateStats,
  AggregateTimeSeries,
  ErrorBoundary,
  SeriesChart,
} from 'react-tectonic';

import {
  getColors,
  mergeFilters,
  sessionsExclusionFilter,
} from 'utils/visualizations';
import TermsDonutChart from 'components/analytics/TermsDonutChart';
import TermsTable from 'components/analytics/TermsTable';
import MultiCardinalityDonutChart from 'components/analytics/MultiCardinalityDonutChart';
import { formatEuro } from 'utils/formatting';

import { Link } from 'react-router-dom';
import { useUser } from 'contexts/user';

export default function CpoFinancials({
  includeBillingPlans = true,
  includeLocations = true,
  includeAccounts = true,
  baseFilter = {},
}) {
  const { t } = useTranslation();
  const { provider } = useUser();
  const colorHex = `#${provider.primaryColorHex}`;

  const colors = getColors(colorHex);

  return (
    <ErrorBoundary>
      <Divider hidden />
      <Statistic.Group
        widths="four"
        style={{ justifyContent: 'space-between' }}>
        <AggregateStats
          collection="sessions"
          fields={['energyCosts', 'timeCosts', 'startCosts']}
          filter={mergeFilters(baseFilter, sessionsExclusionFilter)}>
          {({ status, data }) => {
            if (!data) return '...';
            if (!data.energyCosts || !data.timeCosts || !data.startCosts) {
              return '...';
            }
            const totalPrice =
              (data.energyCosts.sum || 0) +
              (data.timeCosts.sum || 0) +
              (data.startCosts.sum || 0);
            return (
              <Statistic>
                <Statistic.Value>
                  {status.success ? formatEuro(totalPrice, 0, true) : '...'}
                </Statistic.Value>
                <Statistic.Label>Revenue</Statistic.Label>
              </Statistic>
            );
          }}
        </AggregateStats>
        <AggregateStats
          collection="sessions"
          fields={['startCosts']}
          filter={mergeFilters(baseFilter, sessionsExclusionFilter)}>
          {({ status, data }) => {
            if (!data) return '...';
            if (!data.startCosts) return '...';
            return (
              <Statistic>
                <Statistic.Value>
                  {status.success
                    ? formatEuro(data['startCosts'].sum, 0, true)
                    : '...'}
                </Statistic.Value>
                <Statistic.Label>Start Costs</Statistic.Label>
              </Statistic>
            );
          }}
        </AggregateStats>
        <AggregateStats
          collection="sessions"
          fields={['timeCosts']}
          filter={mergeFilters(baseFilter, sessionsExclusionFilter)}>
          {({ status, data }) => {
            if (!data) return '...';
            if (!data.timeCosts) return '...';
            return (
              <Statistic>
                <Statistic.Value>
                  {status.success
                    ? formatEuro(data['timeCosts'].sum, 0, true)
                    : '...'}
                </Statistic.Value>
                <Statistic.Label>Time Costs</Statistic.Label>
              </Statistic>
            );
          }}
        </AggregateStats>
        <AggregateStats
          collection="sessions"
          fields={['energyCosts']}
          filter={mergeFilters(baseFilter, sessionsExclusionFilter)}>
          {({ status, data }) => {
            if (!data) return '...';
            if (!data.energyCosts) return '...';
            return (
              <Statistic>
                <Statistic.Value>
                  {status.success
                    ? formatEuro(data['energyCosts'].sum, 0, true)
                    : '...'}
                </Statistic.Value>
                <Statistic.Label>Energy Costs</Statistic.Label>
              </Statistic>
            );
          }}
        </AggregateStats>
      </Statistic.Group>

      <Divider hidden />
      <Divider hidden />

      <AggregateTimeSeries
        collection="sessions"
        operation="sum"
        field="externalCalculatedPrice"
        filter={mergeFilters(baseFilter, sessionsExclusionFilter)}>
        <SeriesChart
          title={t('analyticsSessions.revenue', 'Revenue')}
          titleAlign="center"
          chartType="bar"
          height={350}
          valueField="value"
          valueFieldLabel={t('analyticsSessions.revenue', 'Revenue')}
          valueFormatter={(item) => formatEuro(Math.round(item), 0, true)}
          color={colorHex}
        />
      </AggregateTimeSeries>

      <Divider hidden />
      <Divider hidden />

      <AggregateTimeSeries
        collection="sessions"
        operation="sum"
        field="energyCosts"
        filter={mergeFilters(baseFilter, sessionsExclusionFilter)}>
        <SeriesChart
          title="Energy Costs"
          titleAlign="center"
          chartType="area"
          height={350}
          valueFieldLabel="Energy Costs"
          valueFormatter={(item) => formatEuro(Math.round(item), 0, true)}
          color={colorHex}
        />
      </AggregateTimeSeries>

      {includeAccounts && (
        <React.Fragment>
          <Divider hidden />
          <Divider hidden />

          <Grid>
            <Grid.Row>
              <Grid.Column computer={8} mobile={16}>
                <Segment basic>
                  <TermsTable
                    filter={mergeFilters(baseFilter, sessionsExclusionFilter)}
                    collection="sessions"
                    aggField="accountId"
                    field="externalCalculatedPrice"
                    operation="sum"
                    title="Top Accounts by Revenue"
                    valueField="value"
                    valueFieldName="Revenue"
                    valueFormatter={(item) => {
                      return formatEuro(Math.round(item), 0, true);
                    }}
                    fetchReference="accounts"
                    referenceLabelFormatter={(item) => {
                      return (
                        <Link to={`/accounts/${item.id}`}>{item.name}</Link>
                      );
                    }}
                    termsSize={15}
                  />
                </Segment>
              </Grid.Column>
              <Grid.Column computer={8} mobile={16}>
                <Segment basic>
                  <TermsTable
                    collection="evsecontrollers"
                    filter={baseFilter}
                    aggField="accountId"
                    operation="count"
                    title="Top Accounts by EVSEs"
                    valueField="count"
                    valueFieldName="EVSEs"
                    fetchReference="accounts"
                    referenceLabelFormatter={(item) => {
                      return (
                        <Link to={`/accounts/${item.id}`}>{item.name}</Link>
                      );
                    }}
                    termsSize={15}
                  />
                </Segment>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </React.Fragment>
      )}

      {includeLocations && (
        <React.Fragment>
          <Divider hidden />
          <Divider hidden />

          <Grid>
            <Grid.Row>
              <Grid.Column computer={8} mobile={16}>
                <Segment basic>
                  <TermsTable
                    collection="sessions"
                    aggField="locationId"
                    filter={mergeFilters(baseFilter, sessionsExclusionFilter)}
                    field="externalCalculatedPrice"
                    operation="sum"
                    title="Top Locations by Revenue"
                    valueField="value"
                    valueFieldName="Revenue"
                    valueFormatter={(item) => {
                      return formatEuro(Math.round(item), 0, true);
                    }}
                    fetchReference="locations"
                    referenceLabelFormatter={(item) => {
                      return (
                        <Link to={`/charging-stations/locations/${item.id}`}>
                          {item.name}
                        </Link>
                      );
                    }}
                    termsSize={15}
                  />
                </Segment>
              </Grid.Column>
              <Grid.Column computer={8} mobile={16}>
                <Segment basic>
                  <TermsTable
                    collection="evsecontrollers"
                    aggField="locationId"
                    operation="count"
                    title="Top Locations by EVSEs"
                    valueField="count"
                    valueFieldName="EVSEs"
                    fetchReference="locations"
                    filter={baseFilter}
                    referenceLabelFormatter={(item) => {
                      return (
                        <Link to={`/charging-stations/locations/${item.id}`}>
                          {item.name}
                        </Link>
                      );
                    }}
                    termsSize={15}
                  />
                </Segment>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </React.Fragment>
      )}

      <Divider hidden />
      <Divider hidden />

      <Grid>
        <Grid.Row>
          <Grid.Column computer={8} mobile={16}>
            <Segment basic>
              <TermsDonutChart
                colors={colors}
                title="Session Token Type"
                collection="sessions"
                aggField="tokenType"
                filter={mergeFilters(baseFilter, sessionsExclusionFilter)}
              />
            </Segment>
          </Grid.Column>
          <Grid.Column computer={8} mobile={16}>
            <Segment basic>
              <MultiCardinalityDonutChart
                colors={colors}
                title="Sessions Billing Status"
                collection="sessions"
                filter={sessionsExclusionFilter}
                items={[
                  {
                    label: 'Included',
                    request: {
                      collection: 'sessions',
                      fields: ['id'],
                      filter: {
                        ...mergeFilters(
                          {
                            terms: [{ excluded: true }],
                          },
                          baseFilter
                        ),
                      },
                    },
                  },
                  {
                    label: 'Excluded',
                    request: {
                      collection: 'sessions',
                      fields: ['id'],
                      filter: {
                        ...mergeFilters(
                          {
                            terms: [{ excluded: false }],
                          },
                          baseFilter
                        ),
                      },
                    },
                  },
                ]}
              />
            </Segment>
          </Grid.Column>
        </Grid.Row>
      </Grid>

      <Divider hidden />
      <Divider hidden />

      <Grid>
        <Grid.Row>
          <Grid.Column computer={8} mobile={16}>
            <Segment basic>
              <MultiCardinalityDonutChart
                title="Roaming"
                colors={colors}
                collection="evsecontrollers"
                filter={baseFilter}
                items={[
                  {
                    label: 'Enabled',
                    request: {
                      collection: 'evsecontrollers',
                      fields: ['id'],
                      filter: {
                        ...mergeFilters(
                          {
                            terms: [{ enablePublicCharging: true }],
                          },
                          baseFilter
                        ),
                      },
                    },
                  },
                  {
                    label: 'Disabled',
                    request: {
                      collection: 'evsecontrollers',
                      fields: ['id'],
                      filter: {
                        ...mergeFilters(
                          {
                            terms: [{ enablePublicCharging: false }],
                          },
                          baseFilter
                        ),
                      },
                    },
                  },
                ]}
              />
            </Segment>
          </Grid.Column>
          <Grid.Column computer={8} mobile={16}>
            <Segment basic>
              <MultiCardinalityDonutChart
                title="Free Charging"
                colors={colors}
                collection="evsecontrollers"
                filter={baseFilter}
                items={[
                  {
                    label: 'Enabled',
                    request: {
                      collection: 'evsecontrollers',
                      fields: ['id'],
                      filter: {
                        ...mergeFilters(
                          {
                            terms: [{ enablePublicFreeCharging: true }],
                          },
                          baseFilter
                        ),
                      },
                    },
                  },
                  {
                    label: 'Disabled',
                    request: {
                      collection: 'evsecontrollers',
                      fields: ['id'],
                      filter: {
                        ...mergeFilters(
                          {
                            terms: [{ enablePublicFreeCharging: false }],
                          },
                          baseFilter
                        ),
                      },
                    },
                  },
                ]}
              />
            </Segment>
          </Grid.Column>
        </Grid.Row>
      </Grid>

      {includeBillingPlans && (
        <React.Fragment>
          <Divider hidden />
          <Divider hidden />

          <Grid>
            <Grid.Row>
              <Grid.Column computer={8} mobile={16}>
                <Segment basic>
                  <MultiCardinalityDonutChart
                    title="EVSE Billing Status"
                    collection="evsecontrollers"
                    items={[
                      {
                        label: 'Active',
                        request: {
                          collection: 'evsecontrollers',
                          fields: ['id'],
                          filter: {
                            ...mergeFilters(
                              {
                                terms: [{ isDisabled: false }],
                              },
                              baseFilter
                            ),
                          },
                        },
                      },
                      {
                        label: 'Disabled',
                        request: {
                          collection: 'evsecontrollers',
                          fields: ['id'],
                          filter: {
                            ...mergeFilters(
                              {
                                terms: [{ isDisabled: true }],
                              },
                              baseFilter
                            ),
                          },
                        },
                      },
                    ]}
                  />
                </Segment>
              </Grid.Column>
              <Grid.Column computer={8} mobile={16}>
                <Segment basic>
                  <TermsTable
                    collection="evsecontrollers"
                    aggField="billingPlanId"
                    operation="count"
                    valueField="count"
                    valueFieldName="EVSEs"
                    title="EVSE Billing Plans"
                    termsSize={15}
                    fetchReference="billingplans"
                    filter={baseFilter}
                    referenceLabelFormatter={(item) => {
                      return item.details.en.name;
                    }}
                  />
                </Segment>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </React.Fragment>
      )}

      <Divider hidden />
      <Divider hidden />

      <Grid>
        <Grid.Row>
          <Grid.Column computer={8} mobile={16}>
            <Segment basic>
              <TermsTable
                collection="sessions"
                aggField="tokenInfraProviderId"
                title="Mobility Service Providers"
                valueField="count"
                valueFieldName="Sessions"
                filter={baseFilter}
                termsSize={15}
                labelFormatter={(key) => key.toUpperCase()}
              />
            </Segment>
          </Grid.Column>
          <Grid.Column computer={8} mobile={16}>
            <Segment basic>
              <TermsTable
                collection="sessions"
                aggField="tokenInfraProviderId"
                title="Mobility Service Providers"
                operation="sum"
                field="externalCalculatedPrice"
                valueField="value"
                valueFieldName="Revenue"
                termsSize={15}
                filter={baseFilter}
                labelFormatter={(key) => key.toUpperCase()}
                valueFormatter={(item) => {
                  return formatEuro(Math.round(item), 0, true);
                }}
              />
            </Segment>
          </Grid.Column>
        </Grid.Row>
      </Grid>

      <Divider hidden />
      <Divider hidden />
    </ErrorBoundary>
  );
}
