import React from 'react';
import { Divider, Menu, FormField } from 'semantic';
import { withTranslation } from 'react-i18next';
import { Switch, Route, NavLink, Link } from 'react-router-dom';
import Breadcrumbs from 'components/Breadcrumbs';

import Overview from 'components/analytics-screens/cpo/Overview';
import Transactions from 'components/analytics-screens/cpo/Transactions';
import Financials from 'components/analytics-screens/cpo/Financials';
import Locations from 'components/analytics-screens/cpo/Locations';
import Equipment from 'components/analytics-screens/cpo/Equipment';
import SearchDropdown from 'components/form-fields/SearchDropdown';
import { request } from 'utils/api';
import { mergeFilters } from 'utils/visualizations';

const screens = [
  {
    id: 'overview',
    name: 'Overview',
    component: Overview,
  },
  {
    id: 'transactions',
    name: 'Transactions',
    component: Transactions,
  },
  {
    id: 'financials',
    name: 'Financials',
    component: Financials,
  },
  {
    id: 'locations',
    name: 'Locations',
    component: Locations,
  },
  {
    id: 'equipment',
    name: 'Equipment',
    component: Equipment,
  },
];

class CpoAnalytics extends React.Component {
  state = {
    accountId: null,
    locationId: null,
  };
  render() {
    const { t, locations } = this.props;
    const { accountId, locationId } = this.state;

    return (
      <div>
        <Breadcrumbs
          path={[
            <Link key="link" to="/analytics">
              {t('analytics.title', 'Analytics')}
            </Link>,
          ]}
          active={t('menu.analyticsCpo', 'CPO')}
        />
        <div style={{ display: 'flex', gap: '15px', flexWrap: 'wrap' }}>
          <FormField>
            <label>
              {t('analytics.filterByAccounts', 'Filter by accounts')}: &nbsp;
            </label>
            <SearchDropdown
              value={accountId}
              placeholder={t('analytics.allAccounts', 'All accounts')}
              clearable
              objectMode={false}
              onDataNeeded={(body) => {
                return request({
                  path: '/1/accounts/search',
                  method: 'POST',
                  body,
                });
              }}
              onChange={(e, { value: accountId }) =>
                this.setState({ accountId, locationId: null })
              }
            />
          </FormField>
          <FormField>
            <label>
              {t('myUsage.filterByLocation', 'Filter by locations')}: &nbsp;
            </label>
            <SearchDropdown
              value={locationId}
              placeholder={t('myUsage.allLocations', 'All locations')}
              clearable
              objectMode={false}
              onDataNeeded={(body) => {
                return request({
                  path: '/2/locations/cpo/search',
                  method: 'POST',
                  body: {
                    ...body,
                    ...(accountId ? { accountId } : {}),
                    sort: {
                      order: 'desc',
                      field: 'createdAt',
                    },
                  },
                });
              }}
              onChange={(e, { value: locationId }) =>
                this.setState({ locationId })
              }
            />
          </FormField>
        </div>
        {this.renderMenu()}
        <Divider hidden />
        <Switch>
          {screens.map((screen) => {
            return (
              <Route
                key={screen.id}
                exact
                path={
                  screen.id === 'overview'
                    ? `/analytics/cpo`
                    : `/analytics/cpo/${screen.id}`
                }
                render={() => (
                  <screen.component
                    {...(screen.props || {})}
                    locations={locations}
                    baseFilter={mergeFilters(
                      accountId ? { terms: [{ accountId }] } : {},
                      locationId ? { terms: [{ locationId }] } : {}
                    )}
                  />
                )}
              />
            );
          })}
        </Switch>
      </div>
    );
  }

  renderMenu() {
    return (
      <Menu fluid pointing secondary>
        {screens.map(({ id, name }) => {
          return (
            <Menu.Item
              key={id}
              exact
              content={name}
              to={id === 'overview' ? `/analytics/cpo` : `/analytics/cpo/${id}`}
              as={NavLink}
            />
          );
        })}
      </Menu>
    );
  }
}

export default withTranslation()(CpoAnalytics);
