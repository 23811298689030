import React from 'react';
import {
  Header,
  Table,
  Item,
  Message,
  SemanticCOLORS,
} from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import { hasConnectorInfo } from 'utils/evse-controllers';
import EvseConnector from 'components/EvseConnector';
import { formatDateTime } from 'utils/date';
import {
  EvseControllerConnectorEvseIds,
  EvseControllerEvseId,
} from 'components/EvseId';
import { Label } from 'semantic';

const EvseControllerOverview = ({ evseController, maintainerMode }) => {
  const { bootInfo, connectors, accessGroups } = evseController;

  const pairedChargeCardSyncStatus = (status: string) => {
    let color: SemanticCOLORS;
    let content: string;

    switch (status) {
      case 'InProgress':
        color = 'blue';
        content = 'Pending';
        break;
      case 'Completed':
        color = 'green';
        content = 'Paired';
        break;
      case 'Failed':
        color = 'red';
        content = 'Failed';
        break;
      default:
        return;
    }

    return <Label content={content} color={color} />;
  };

  return (
    <div>
      <Header as="h3">Connectors</Header>
      {hasConnectorInfo(evseController) ? (
        <Item.Group divided>
          {connectors.map((connector: any) => {
            const connectorStatus =
              evseController.connectorStatus?.[connector.connectorId]?.status;
            const evseId =
              connector.evseId ||
              connector.evseIdRef?.identifier ||
              evseController.evseId ||
              evseController.evseIdRef?.identifier;
            return (
              <EvseConnector
                connectorStandard={connector.standard}
                key={connector.connectorId}
                evseId={evseId}
                powerType={connector.powerType}
                connectorFormat={connector.format}
                status={connectorStatus}
                activeSession={null}
              />
            );
          })}
        </Item.Group>
      ) : (
        <Message content="No connectors configured yet" />
      )}

      <Header as="h3">Access Groups</Header>
      {accessGroups.length ? (
        <Table definition collapsing>
          <Table.Body>
            {accessGroups.map((accessGroup) => (
              <Table.Row key={accessGroup.id}>
                <Table.Cell>{accessGroup.name}</Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      ) : (
        <Message content="No access groups configured yet" />
      )}

      <Header as="h3">Details</Header>
      <Table definition>
        <Table.Body>
          <Table.Row>
            <Table.Cell>Location</Table.Cell>
            <Table.Cell>
              {evseController.location ? (
                maintainerMode ? (
                  evseController.location.name || 'No Name'
                ) : (
                  <Link
                    to={`/charging-stations/locations/${evseController.location.id}`}>
                    {evseController.location.name || 'No Name'}
                  </Link>
                )
              ) : (
                '-'
              )}
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>Account</Table.Cell>
            <Table.Cell>
              {evseController.account ? (
                maintainerMode ? (
                  evseController.account.name || 'No Name'
                ) : (
                  <Link to={`/accounts/${evseController.account.id}`}>
                    {evseController.account.name || 'No Name'}
                  </Link>
                )
              ) : (
                '-'
              )}
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>Field Service Account</Table.Cell>
            <Table.Cell>
              {evseController.maintenanceAccount ? (
                maintainerMode ? (
                  evseController.maintenanceAccount.name || 'No Name'
                ) : (
                  <Link
                    to={`/accounts/${evseController.maintenanceAccount.id}`}>
                    {evseController.maintenanceAccount.name || 'No Name'}
                  </Link>
                )
              ) : (
                '-'
              )}
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>Billing Plan</Table.Cell>
            <Table.Cell>
              {evseController.billingPlan &&
                evseController.billingPlan.details.en.name}
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>OCPP Identity</Table.Cell>
            <Table.Cell>{evseController.ocppIdentity}</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>EVSE ID</Table.Cell>
            <Table.Cell>
              <EvseControllerEvseId evseController={evseController} />
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>EVSE IDs (Connectors)</Table.Cell>
            <Table.Cell>
              <EvseControllerConnectorEvseIds evseController={evseController} />
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>Serial Number</Table.Cell>
            <Table.Cell>
              {evseController.serialNumber ||
                evseController.bootInfo?.chargePointSerialNumber}
            </Table.Cell>
          </Table.Row>
          {evseController.pairedChargeCardId && (
            <Table.Row>
              <Table.Cell>Paired Charge Card ID</Table.Cell>
              <Table.Cell>
                {evseController.pairedChargeCardId}
                &nbsp;&nbsp;&nbsp;
                {pairedChargeCardSyncStatus(
                  evseController.pairedChargeCardSync?.status
                )}
              </Table.Cell>
            </Table.Row>
          )}
          <Table.Row>
            <Table.Cell>Created At</Table.Cell>
            <Table.Cell>{formatDateTime(evseController.createdAt)}</Table.Cell>
          </Table.Row>
          {evseController.activatedAt && (
            <Table.Row>
              <Table.Cell>Activated At</Table.Cell>
              <Table.Cell>
                {formatDateTime(evseController.activatedAt)}
              </Table.Cell>
            </Table.Row>
          )}
          <Table.Row>
            <Table.Cell>Updated At</Table.Cell>
            <Table.Cell>{formatDateTime(evseController.updatedAt)}</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>Provider</Table.Cell>
            <Table.Cell>{evseController.provider.name}</Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>

      {bootInfo && (
        <>
          <Header as="h3">Boot Info</Header>
          <Table definition>
            <Table.Body>
              <Table.Row>
                <Table.Cell>Vendor</Table.Cell>
                <Table.Cell>{bootInfo.chargePointVendor || '-'}</Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>Model</Table.Cell>
                <Table.Cell>{bootInfo.chargePointModel || '-'}</Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>Serial Number</Table.Cell>
                <Table.Cell>
                  {bootInfo.chargePointSerialNumber || '-'}
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>Box Serial Number</Table.Cell>
                <Table.Cell>{bootInfo.chargeBoxSerialNumber || '-'}</Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>Firmware Version</Table.Cell>
                <Table.Cell>{bootInfo.firmwareVersion || '-'}</Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>IMSI</Table.Cell>
                <Table.Cell>{bootInfo.imsi || '-'}</Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>ICCID</Table.Cell>
                <Table.Cell>{bootInfo.iccid || '-'}</Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>Meter Type</Table.Cell>
                <Table.Cell>{bootInfo.meterType || '-'}</Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>Meter Serial Number</Table.Cell>
                <Table.Cell>{bootInfo.meterSerialNumber || '-'}</Table.Cell>
              </Table.Row>
            </Table.Body>
          </Table>
        </>
      )}
    </div>
  );
};

export default EvseControllerOverview;
