import React from 'react';
import { Divider, Statistic } from 'semantic';
import { startCase } from 'lodash-es';
import { useTranslation } from 'react-i18next';
import {
  AggregateStats,
  AggregateTimeMap,
  AggregateTimeSeries,
  PunchChart,
  SeriesChart,
} from 'react-tectonic';

import { mergeFilters, sessionsExclusionFilter } from 'utils/visualizations';
import {
  calculateKwh,
  formatDurationWithUnit,
  numberWithCommas,
} from 'utils/formatting';
import { useUser } from 'contexts/user';

export default function MspTransactions({ baseFilter = {} }) {
  const { t } = useTranslation();

  const { provider } = useUser();
  const colorHex = `#${provider.primaryColorHex}`;

  return (
    <div>
      <Divider hidden />
      <Divider hidden />

      <Statistic.Group
        widths="five"
        style={{ justifyContent: 'space-between' }}>
        <AggregateStats
          collection="sessions"
          fields={['durationSeconds']}
          filter={mergeFilters(baseFilter, sessionsExclusionFilter)}>
          {({ status, data }) => {
            if (!data) return '...';
            if (!data.durationSeconds) return '...';
            const { value, unit } = formatDurationWithUnit(
              data['durationSeconds'].sum
            );
            return (
              <Statistic>
                <Statistic.Value>
                  {status.success ? numberWithCommas(value) : '...'}
                </Statistic.Value>
                <Statistic.Label>{startCase(unit)} of charging</Statistic.Label>
              </Statistic>
            );
          }}
        </AggregateStats>
        <AggregateStats
          collection="sessions"
          fields={['durationSeconds']}
          filter={mergeFilters(baseFilter, sessionsExclusionFilter)}>
          {({ status, data }) => {
            if (!data) return '...';
            if (!data.durationSeconds) return '...';
            const { value, unit } = formatDurationWithUnit(
              data['durationSeconds'].avg
            );
            return (
              <Statistic>
                <Statistic.Value>
                  {status.success ? numberWithCommas(value) : '...'}
                </Statistic.Value>
                <Statistic.Label>{startCase(unit)} session avg</Statistic.Label>
              </Statistic>
            );
          }}
        </AggregateStats>
        <AggregateStats
          collection="sessions"
          fields={['kwh']}
          filter={mergeFilters(baseFilter, sessionsExclusionFilter)}>
          {({ status, data }) => {
            if (!data) return '...';
            if (!data.kwh) return '...';
            const { kwhValue, kwhUnit } = calculateKwh(data['kwh'].sum);
            return (
              <Statistic>
                <Statistic.Value>
                  {status.success ? kwhValue || 0 : '...'}
                </Statistic.Value>
                <Statistic.Label>{kwhUnit} total</Statistic.Label>
              </Statistic>
            );
          }}
        </AggregateStats>
        <AggregateStats
          collection="sessions"
          fields={['kwh']}
          filter={mergeFilters(baseFilter, sessionsExclusionFilter)}>
          {({ status, data }) => {
            if (!data) return '...';
            if (!data.kwh) return '...';
            const { kwhValue, kwhUnit } = calculateKwh(data['kwh'].avg);
            return (
              <Statistic>
                <Statistic.Value>
                  {status.success ? numberWithCommas(kwhValue) : '...'}
                </Statistic.Value>
                <Statistic.Label>{kwhUnit} session avg</Statistic.Label>
              </Statistic>
            );
          }}
        </AggregateStats>
        <AggregateStats
          collection="sessions"
          fields={['kwh', 'durationSeconds']}
          filter={mergeFilters(baseFilter, sessionsExclusionFilter)}>
          {({ status, data }) => {
            if (!data) return '...';
            if (!data.kwh) return '...';
            const kwPerHour = data.kwh.sum / (data.durationSeconds.sum / 3600);
            return (
              <Statistic>
                <Statistic.Value>
                  {status.success
                    ? kwPerHour
                      ? kwPerHour.toFixed(2)
                      : 0
                    : '...'}
                </Statistic.Value>
                <Statistic.Label>avg kwh/hour</Statistic.Label>
              </Statistic>
            );
          }}
        </AggregateStats>
      </Statistic.Group>

      <Divider hidden />
      <Divider hidden />

      <AggregateTimeSeries
        collection="sessions"
        operation="count"
        filter={mergeFilters(baseFilter, sessionsExclusionFilter)}>
        <SeriesChart
          title={t('analyticsSessions.sessions', 'Sessions')}
          titleAlign="center"
          chartType="bar"
          height={350}
          valueField="count"
          valueFieldLabel={t('analyticsSessions.sessions', 'Sessions')}
          color={colorHex}
        />
      </AggregateTimeSeries>

      <Divider hidden />
      <Divider hidden />

      <AggregateTimeSeries
        collection="sessions"
        operation="sum"
        field="kwh"
        filter={mergeFilters(baseFilter, sessionsExclusionFilter)}>
        <SeriesChart
          title={t('analyticsSessions.kWh', 'kWh')}
          titleAlign="center"
          chartType="area"
          height={350}
          valueFieldLabel="kWh"
          color={colorHex}
        />
      </AggregateTimeSeries>

      <Divider hidden />
      <Divider hidden />

      <AggregateTimeMap
        collection="sessions"
        dateField="startedAt"
        filter={baseFilter}>
        <PunchChart
          titleAlign="center"
          title={t('analyticsSessions.startTimes', 'Session Start Times')}
          height={490}
          color={colorHex}
        />
      </AggregateTimeMap>

      <Divider hidden />
      <Divider hidden />

      <AggregateTimeMap
        collection="sessions"
        dateField="endedAt"
        filter={baseFilter}>
        <PunchChart
          titleAlign="center"
          title={t('analyticsSessions.endTimes', 'Session End Times')}
          height={490}
          color={colorHex}
        />
      </AggregateTimeMap>
    </div>
  );
}
