import React from 'react';
import { set, get } from 'lodash-es';
import { request } from 'utils/api';

import { Form, Message, Modal, Header, Segment, Button } from 'semantic';
import { simpleOptions } from 'utils/form';
import CountriesField from 'components/form-fields/Countries';
import PointerMap from 'components/maps/Pointer';

import SearchDropdown from 'components/form-fields/SearchDropdown';
import { getParkingTypeOptions, getFacilityOptions } from 'utils/locations';

import AccessPolicyConfiguration from './AccessPolicyConfiguration';
import { getAccessPoliciesOptions } from 'utils/locations';
import { PlatformFeature } from 'components';
import AsyncModal from 'helpers/async-modal';
import useAccountHasFeature from 'hooks/useAccountHasFeature';
import { useFeatures } from 'contexts/features';

function EditLocation({
  data = {
    accessPolicy: 'businessReimburse',
    country: 'NLD',
    publishingMode: 'private',
  },
  close,
}) {
  const [formValues, setFormValues] = React.useState(data);
  const [submitted, setSubmitted] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState(null);
  const { hasFeature, isReady } = useFeatures();

  const splitReimburseEnabled = useAccountHasFeature(
    'advanced-billing:split-billing',
    formValues.accountId
  );

  const communityReimburseEnabled = useAccountHasFeature(
    'advanced-billing:community-reimburse',
    formValues.accountId
  );

  const accessPoliciesWithConfigurableBillingPolicy = [
    'communityReimburse',
    'splitReimburse',
  ];

  const isUpdate = !!data?.id;

  const handleSubmit = () => {
    setSubmitted(true);
    setLoading(true);
    setError(null);

    const body = {
      ...formValues,
      billingPolicy: accessPoliciesWithConfigurableBillingPolicy.includes(
        formValues.accessPolicy
      )
        ? formValues.billingPolicy
        : undefined,
    };
    const isUpdate = !!data?.id;
    const method = isUpdate ? 'PATCH' : 'POST';
    const path = isUpdate ? `/2/locations/cpo/${data.id}` : '/2/locations/cpo';
    request({
      method,
      path,
      body,
    })
      .then(() => {
        setLoading(false);

        close();

        setFormValues(data);
      })
      .catch((error) => {
        setError(error);
        setLoading(false);
      });
  };

  const setField = (name, value) => {
    setSubmitted(false);
    setFormValues(set({ ...formValues }, name, value));
  };

  const getMapProps = () => {
    const address = [
      formValues.address,
      formValues.postal_code,
      formValues.city,
      formValues.country,
    ];
    const { coordinates } = formValues.geoLocation || {};
    const hasCompleteAddress = address.every((c) => !!c);

    const isValidLocation =
      coordinates && coordinates[0] !== 135 && coordinates[1] !== 90;

    const geoPosition = isValidLocation && {
      longitude: coordinates[0],
      latitude: coordinates[1],
    };

    if (geoPosition) {
      return {
        value: geoPosition,
      };
    }
    return { address: hasCompleteAddress ? address.join(', ') : undefined };
  };

  return (
    <>
      <Modal.Header>{isUpdate ? `Edit Location` : 'New Location'}</Modal.Header>
      <Modal.Content>
        <Form
          id="EditLocation-form"
          error={submitted && Boolean(error)}
          onSubmit={() => handleSubmit()}>
          {error && <Message error content={error.message} />}
          <Form.Input
            value={formValues.name || ''}
            name="name"
            required
            label="Name"
            type="text"
            onChange={(e, { name, value }) => setField(name, value)}
          />
          <Form.Field required>
            <label>Account</label>
            <SearchDropdown
              value={formValues.accountId}
              objectMode={false}
              onDataNeeded={(body) => {
                return request({
                  path: '/1/accounts/search',
                  method: 'POST',
                  body: body,
                });
              }}
              onChange={(e, { value }) =>
                setField('accountId', value.length ? value : null)
              }
            />
          </Form.Field>
          <Form.Field>
            <label>Facilitator Account</label>
            <SearchDropdown
              clearable
              value={formValues.facilitatorAccountId}
              objectMode={false}
              onDataNeeded={(body) => {
                return request({
                  path: '/1/accounts/search',
                  method: 'POST',
                  body: body,
                });
              }}
              onChange={(e, { value }) =>
                setField('facilitatorAccountId', value.length ? value : null)
              }
            />
          </Form.Field>
          <Form.Field>
            <label>User</label>
            <SearchDropdown
              keywordField="searchPhrase"
              key={formValues.accountId}
              value={formValues.userId}
              objectMode={false}
              onDataNeeded={(body) => {
                return request({
                  path: '/1/users/search/fast',
                  method: 'POST',
                  body: {
                    ...body,
                    accountId: formValues.accountId,
                  },
                });
              }}
              onChange={(e, { value }) =>
                setField('userId', value.length ? value : null)
              }
            />
          </Form.Field>
          {formValues.accessPolicy === 'employeeReimburse' &&
            (!formValues.accountId ? (
              <Message content="Please select an account" />
            ) : (
              <Form.Field>
                <label>Employee</label>
                <SearchDropdown
                  keywordField="searchPhrase"
                  value={formValues.userId}
                  clearable
                  objectMode={false}
                  getOptionLabel={(item) => `${item.name} (${item.email})`}
                  onDataNeeded={(body) => {
                    return request({
                      path: '/1/users/search/fast',
                      method: 'POST',
                      body: {
                        accountId: formValues.accountId,
                        ...body,
                      },
                    });
                  }}
                  onChange={(e, { value }) =>
                    setField('userId', value.length ? value : null)
                  }
                />
              </Form.Field>
            ))}
          <Header as="h3">Address</Header>
          <Segment>
            <Form.Input
              value={formValues.address || ''}
              name="address"
              required
              label="Address / Street"
              type="text"
              autoComplete="disabled"
              onChange={(e, { name, value }) => setField(name, value)}
            />
            <Form.Input
              value={formValues.postal_code || ''}
              name="postal_code"
              required
              label="Postal Code / Zip"
              type="text"
              autoComplete="disabled"
              onChange={(e, { name, value }) => setField(name, value)}
            />
            <Form.Input
              value={formValues.city || ''}
              name="city"
              required
              label="City"
              type="text"
              autoComplete="disabled"
              onChange={(e, { name, value }) => setField(name, value)}
            />
            <CountriesField
              label="Country"
              name="country"
              required
              standard="alpha-3"
              lowerCase={false}
              autoComplete="disabled"
              value={formValues.country}
              onChange={(value) => setField('country', value)}
            />
          </Segment>

          <Form.Select
            value={formValues?.accessPolicy}
            options={getAccessPoliciesOptions(
              isReady &&
                hasFeature('billing_engine_v2__split_billing') &&
                splitReimburseEnabled,
              isReady &&
                hasFeature('billing_engine_v2__split_billing') &&
                communityReimburseEnabled
            )}
            name="accessPolicy"
            label="Access &amp; Billing Policy"
            required
            type="text"
            onChange={(e, { name, value }) => setField(name, value)}
          />
          {accessPoliciesWithConfigurableBillingPolicy.includes(
            formValues.accessPolicy
          ) && (
            <AccessPolicyConfiguration
              accessPolicy={formValues.accessPolicy}
              configurablePercentage={
                formValues.accessPolicy === 'splitReimburse'
              }
              onChange={(field, value) => setField(field, value)}
              value={formValues?.billingPolicy}
            />
          )}
          <Header as="h3">Publication Settings</Header>
          <Segment>
            <p>
              Public locations will be shared with the international roaming
              system so that it can be found on maps.
            </p>
            <Form.Select
              value={formValues.publishingMode}
              options={simpleOptions(['private', 'public'])}
              name="publishingMode"
              label="Visibility"
              required
              type="text"
              onChange={(e, { name, value }) => setField(name, value)}
            />
            {formValues.publishingMode === 'public' && (
              <>
                <PlatformFeature feature={'roaming:suboperator-locations'}>
                  <Form.Input
                    value={get(formValues, 'suboperator.name', '')}
                    name="suboperator.name"
                    label={'Suboperator Name'}
                    type="text"
                    onChange={(e, { name, value }) => setField(name, value)}
                  />
                </PlatformFeature>
                <Form.Select
                  value={formValues.parking_type}
                  options={getParkingTypeOptions()}
                  name="parking_type"
                  label="Parking Type"
                  type="text"
                  onChange={(e, { name, value }) => setField(name, value)}
                />
                <Form.Select
                  multiple
                  value={formValues.facilities}
                  options={getFacilityOptions()}
                  name="facilities"
                  label="Available Facilities"
                  type="text"
                  onChange={(e, { name, value }) => {
                    setField(name, value);
                  }}
                />
              </>
            )}

            <div style={{ height: '300px', marginBottom: '5px' }}>
              <PointerMap
                onChange={(position) => {
                  setField('geoLocation', {
                    type: 'Point',
                    coordinates: [position.longitude, position.latitude],
                  });
                }}
                {...getMapProps()}
              />
            </div>

            <a
              onClick={() => {
                setField('geoLocation', undefined);
              }}>
              Set Position to Address
            </a>
          </Segment>
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button
          loading={loading}
          primary
          content={isUpdate ? 'Update' : 'Create'}
          form="EditLocation-form"
        />
      </Modal.Actions>
    </>
  );
}

export default AsyncModal(EditLocation);
