import { Message, Segment } from 'semantic-ui-react';
import { formatDuration } from 'utils/date';
import { Button, Modal, Loader } from 'semantic';
import React from 'react';
import { ErrorSummary } from 'components/modals/ImportTokenBatch/ErrorSummary';
import { useTranslation } from 'react-i18next';
import {
  BatchItem,
  BatchMessage,
} from 'components/modals/ImportTokenBatch/types';
import sleep from 'utils/sleep';
import { request } from 'utils/api';
import { BatchDetails } from 'components/modals/ImportTokenBatch/BatchDetailsScreen';

type Props = {
  items: BatchItem[];
  batchDetails: BatchDetails;
  onDone: () => void;
};

export function CommitScreen({ items, batchDetails, onDone }: Props) {
  const { t } = useTranslation();

  const [loading, setLoading] = React.useState(true);
  const [totalAssigned, setTotalAssigned] = React.useState<number>(0);
  const [batchErrors, setBatchErrors] = React.useState<BatchMessage[]>([]);
  const [error, setError] = React.useState<Error | undefined>();
  const startTimestamp = Date.now();

  React.useEffect(() => {
    if (items.length === 0) {
      setLoading(false);
      setError(new Error('No items to import'));
      return;
    }

    commit();
  }, []);

  const commit = () => {
    storeBatchWithRetry(items, batchDetails)
      .then(({ totalAssigned, errors, error }) => {
        setLoading(false);
        setTotalAssigned(totalAssigned);
        setBatchErrors(errors);
        setError(error);
      })
      .catch((error) => {
        setLoading(false);
        setError(error);
      });
  };

  return (
    <>
      <Modal.Content>
        {((): React.ReactNode => {
          if (loading) {
            return (
              <Segment style={{ height: '100px' }}>
                <Loader active>
                  {t('tokenBatch.importing', 'Importing, please wait...')}
                </Loader>
              </Segment>
            );
          }

          if (error) {
            return (
              <Message
                error
                content={t(
                  'tokenBatch.importError',
                  'Something went wrong while importing the token batch.'
                )}
              />
            );
          }

          if (batchErrors?.length) {
            return (
              <>
                <p>
                  Import took{' '}
                  {formatDuration(
                    Math.round((Date.now() - startTimestamp) / 1000)
                  )}
                  .
                </p>
                <div>
                  <p>
                    {t(
                      'tokenBatch.importDataImported',
                      'Imported {{itemsCount}} Tokens.',
                      {
                        itemsCount: items.length,
                      }
                    )}
                  </p>
                  {totalAssigned > 0 ? (
                    <p>
                      {t(
                        'tokenBatch.totalDataAssigned',
                        'Processed {{totalAssigned}} Tokens.',
                        {
                          totalAssigned: totalAssigned,
                        }
                      )}
                    </p>
                  ) : (
                    ''
                  )}
                  <Message error>
                    <p>
                      {t(
                        'tokenBatch.importDataError',
                        'Received the following errors while importing:',
                        {
                          errorCount: batchErrors.length,
                        }
                      )}
                    </p>
                  </Message>
                  <ErrorSummary errors={batchErrors} />
                </div>
              </>
            );
          }

          return (
            <>
              <p>
                Import took{' '}
                {formatDuration(
                  Math.round((Date.now() - startTimestamp) / 1000)
                )}
                .
              </p>
              <p>
                {t(
                  'tokenBatch.importDataSuccess',
                  'Imported {{itemsCount}} records successfully!',
                  {
                    itemsCount: items.length,
                  }
                )}
              </p>
            </>
          );
        })()}
      </Modal.Content>
      <Modal.Actions>
        <Button
          // @ts-ignore
          content={t('importSessions.done', 'Done')}
          primary
          disabled={!!loading}
          loading={loading}
          onClick={onDone}
        />
      </Modal.Actions>
    </>
  );
}

type BatchResults = {
  batchId: string;
  totalImported: number;
  totalAssigned: number;
  skipped?: BatchMessage[];
  errors?: BatchMessage[];
};

export type HandlerResponse<T extends object = object> = {
  data?: T; // Error response will not have data OCPI 2.1.1 #3.1.5
  error?: Error;
  status: number;
  meta?: { total: number; limit: number; skip: number };
  body?: object; // Additional body fields
};

export async function storeBatchWithRetry(
  batch: BatchItem[],
  batchDetails: BatchDetails,
  attempt = 1
): Promise<{
  totalAssigned: number;
  errors: BatchMessage[];
  error?: Error;
}> {
  try {
    const { data } = await request<HandlerResponse<BatchResults>>({
      method: 'POST',
      path: `/1/tokens/batch/import`,
      body: { ...batchDetails, batch: batch.map((body) => ({ ...body })) },
    });
    return {
      totalAssigned: data?.totalAssigned || 0,
      errors: data ? extractBatchErrors(data) : [],
    };
  } catch (error: any) {
    if (attempt === 3) {
      throw error;
    }

    await sleep(1000);
    return await storeBatchWithRetry(batch, batchDetails, attempt + 1);
  }
}

export function extractBatchErrors(batchResults: BatchResults): BatchMessage[] {
  return [...(batchResults.skipped || []), ...(batchResults.errors || [])];
}
