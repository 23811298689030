import React, { useState } from 'react';

import { Message, Modal, Button } from 'semantic';
import { useTranslation } from 'react-i18next';
import { EvseController } from 'types/evse-controller';
import { getEvseProtocols } from 'utils/evse-controllers';

type Props = {
  evseControllers: EvseController[];
  trigger: React.ReactNode;
  onDone: () => void;
};

export default function ConfirmWithWarningChangeConfigurationBulk({
  evseControllers,
  onDone,
  trigger,
}: Props) {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);

  const sameProtocol = getEvseProtocols(evseControllers).length === 1;

  return (
    <Modal
      size="small"
      closeIcon
      closeOnDimmerClick={false}
      trigger={trigger}
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      open={open}>
      <Modal.Header>
        {t(
          'confirmWithWarningChangeConfigurationBulk.title',
          'Change Configuration'
        )}
      </Modal.Header>
      <Modal.Content>
        {!sameProtocol && (
          <>
            <Message
              error
              icon="triangle-exclamation"
              header={t(
                'confirmWithWarningChangeConfigurationBulk.messageTitle',
                'Different OCPP versions for charging stations detected'
              )}
              content={t(
                'confirmWithWarningChangeConfigurationBulk.messageContent',
                'Some of the selected charging stations have different OCPP versions.'
              )}
            />
            <p>
              {t(
                'confirmWithWarningChangeConfigurationBulk.confirmWithWarning',
                'Would you like to proceed anyway?'
              )}
            </p>
          </>
        )}
      </Modal.Content>
      <Modal.Actions>
        <Button
          basic
          onClick={() => setOpen(false)}
          content={t('confirmWithWarningChangeConfigurationBulk.back', 'Back')}
        />
        <Button
          primary
          onClick={() => onDone()}
          content={t(
            'confirmWithWarningChangeConfigurationBulk.confirm',
            'Confirm'
          )}
        />
      </Modal.Actions>
    </Modal>
  );
}
