import React from 'react';

import useFetch from 'hooks/useFetch';
import { Link, useParams } from 'react-router-dom';
import { ListHeader, Breadcrumbs, Search } from 'components';
import { useTranslation } from 'react-i18next';
import { Header, Table, Divider, Loader, Message, Button } from 'semantic';

import {
  EvseBulkActionWorkflow,
  EvseControllerBulkActionExecution,
  EvseControllerBulkActionStatus,
} from 'types/evse-controller-bulk-action-execution';
import { formatDateTime } from 'utils/date';
import EvseControllerBulkActionExecutionStatus from '../EvseControllerBulkActionExecutionStatus';
import InspectObject from 'components/modals/InspectObject';
import {
  CHARGING_STATIONS_FE_PATH,
  EVSE_BULK_ACTIONS_BE_PATH,
  EVSE_CONTROLLERS_BACKGROUND_JOBS_FE_PATH,
  USERS_FE_PATH,
} from '../utils';
import WorkflowsTable from 'screens/EvseControllersBackgroundJobs/Detail/WorkflowsTable';
import WorkflowsFilterHeader, {
  WorkflowFilterFields,
  workflowFilterMapping,
} from 'screens/EvseControllersBackgroundJobs/Detail/WorkflowsFilterHeader';
import { decodeStringForFilter } from 'utils/filters-header';

export default function EvseControllersBackgroundJobsDetail() {
  const { t } = useTranslation();
  const { id } = useParams<{ id: string }>();

  const {
    data: evseControllerBulkActionExecutionData,
    loading: evseControllerBulkActionExecutionLoading,
    error: evseControllerBulkActionExecutionError,
  } = useFetch<EvseControllerBulkActionExecution>({
    path: `${EVSE_BULK_ACTIONS_BE_PATH}/${id}`,
  });

  const showDetails =
    !evseControllerBulkActionExecutionLoading &&
    evseControllerBulkActionExecutionData;
  const showError =
    !evseControllerBulkActionExecutionLoading &&
    evseControllerBulkActionExecutionError;

  const workflowStatuses =
    evseControllerBulkActionExecutionData?.workflows?.reduce(
      (acc, w) => {
        switch (w.status) {
          case EvseControllerBulkActionStatus.Completed:
            acc.completed++;
            break;
          case EvseControllerBulkActionStatus.Failed:
            acc.failed++;
            break;
          case EvseControllerBulkActionStatus.InProgress:
            acc.inProgress++;
            break;
        }
        return acc;
      },
      { completed: 0, failed: 0, inProgress: 0 }
    );

  const numWorkflowsCompleted = workflowStatuses?.completed || 0;
  const numWorkflowsFailed = workflowStatuses?.failed || 0;
  const numWorkflowsInProgress = workflowStatuses?.inProgress || 0;

  function generateStatusMessage(
    numCompleted: number,
    numFailed: number,
    numInProgress: number,
    total: number
  ): string {
    if (
      numFailed > 0 &&
      (numFailed === total ||
        numFailed + numInProgress === total ||
        numFailed + numCompleted === total)
    ) {
      return t(
        'evseControllersBackgroundJobsDetail.statusStationsFailedColumn',
        '{{numFailed}} of {{total}} stations failed',
        {
          numFailed,
          total,
        }
      );
    } else {
      return t(
        'evseControllersBackgroundJobsDetail.statusStationsCompletedColumn',
        '{{totalCompleted}} of {{total}} stations completed',
        {
          numCompleted,
          total,
        }
      );
    }
  }

  return (
    <div>
      <Breadcrumbs
        path={[
          <Link to={CHARGING_STATIONS_FE_PATH}>
            {t(
              'evseControllersBackgroundJobsDetail.breadcrumbsChargingStations',
              'Charging Stations'
            )}
          </Link>,
          <Link to={EVSE_CONTROLLERS_BACKGROUND_JOBS_FE_PATH}>
            {t(
              'evseControllersBackgroundJobsDetail.breadcrumbsBackgroundJobs',
              'Background Jobs'
            )}
          </Link>,
        ]}
        active={id}
      />
      {evseControllerBulkActionExecutionLoading && (
        <div style={{ paddingTop: 200 }}>
          <Loader active />
        </div>
      )}
      {showError && (
        <div>
          <Message error>
            {t(
              'evseControllersBackgroundJobsDetail.failedToLoad',
              'Failed to load job details.'
            )}
          </Message>
        </div>
      )}
      {showDetails && (
        <>
          <div style={{ marginTop: 0, marginBottom: 20 }}>
            <ListHeader
              title={t(
                'evseControllersBackgroundJobsDetail.title',
                'Job Details'
              )}
            />
          </div>
          <div>
            <Header as="h3">
              {t(
                'evseControllersBackgroundJobsDetail.overviewTitle',
                'Overview'
              )}
            </Header>
            <Table definition>
              <Table.Body>
                <Table.Row>
                  <Table.Cell>
                    {t(
                      'evseControllersBackgroundJobsDetail.jobIdColumn',
                      'Job ID'
                    )}
                  </Table.Cell>
                  <Table.Cell>
                    {evseControllerBulkActionExecutionData.id}
                  </Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>
                    {t(
                      'evseControllersBackgroundJobsDetail.jobTypeColumn',
                      'Job Type'
                    )}
                  </Table.Cell>
                  <Table.Cell>
                    <span style={{ paddingRight: 20 }}>
                      {evseControllerBulkActionExecutionData.actionType}
                    </span>
                    <InspectObject
                      name={t(
                        'evseControllersBackgroundJobsDetail.parametersModalTitle',
                        'Parameters for Job {{jobId}}',
                        { jobId: evseControllerBulkActionExecutionData.id }
                      )}
                      data={evseControllerBulkActionExecutionData.parameters}
                      trigger={
                        <Button
                          basic
                          content={t(
                            'evseControllersBackgroundJobsDetail.showParams',
                            'Show Params'
                          )}
                          icon="code"
                        />
                      }
                    />
                  </Table.Cell>
                </Table.Row>
                {evseControllerBulkActionExecutionData.creatorUser && (
                  <Table.Row>
                    <Table.Cell>
                      {t(
                        'evseControllersBackgroundJobsDetail.createdByColumn',
                        'Created By'
                      )}
                    </Table.Cell>
                    <Table.Cell>
                      {evseControllerBulkActionExecutionData.creatorUser && (
                        <Link
                          to={`${USERS_FE_PATH}/${evseControllerBulkActionExecutionData.creatorUser.id}`}
                          target="_blank">
                          {
                            evseControllerBulkActionExecutionData.creatorUser
                              .contact?.firstName
                          }{' '}
                          {
                            evseControllerBulkActionExecutionData.creatorUser
                              .contact?.lastName
                          }
                        </Link>
                      )}
                    </Table.Cell>
                  </Table.Row>
                )}
                <Table.Row>
                  <Table.Cell>
                    {t(
                      'evseControllersBackgroundJobsDetail.noteColumn',
                      'Note'
                    )}
                  </Table.Cell>
                  <Table.Cell>
                    {evseControllerBulkActionExecutionData.note}
                  </Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>
                    {t(
                      'evseControllersBackgroundJobsDetail.createdAtColumn',
                      'Created At'
                    )}
                  </Table.Cell>
                  <Table.Cell>
                    {formatDateTime(
                      evseControllerBulkActionExecutionData.createdAt
                    )}
                  </Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>
                    {t(
                      'evseControllersBackgroundJobsDetail.statusColumn',
                      'Status'
                    )}
                  </Table.Cell>
                  <Table.Cell>
                    <EvseControllerBulkActionExecutionStatus
                      status={evseControllerBulkActionExecutionData.status}
                    />
                    <br />
                    <small>
                      {generateStatusMessage(
                        numWorkflowsCompleted,
                        numWorkflowsFailed,
                        numWorkflowsInProgress,
                        evseControllerBulkActionExecutionData.workflowExecutionsNumber
                      )}
                    </small>
                  </Table.Cell>
                </Table.Row>
              </Table.Body>
            </Table>
            <Divider hidden />
            <Search.Provider
              onDataNeeded={async (body) => {
                return {
                  data: applyFilters(
                    evseControllerBulkActionExecutionData?.workflows || [],
                    body as Record<WorkflowFilterFields, any>
                  ),
                };
              }}
              filterMapping={workflowFilterMapping}>
              <WorkflowsFilterHeader
                data={evseControllerBulkActionExecutionData?.workflows}
              />
              <Search.Status />
              <WorkflowsTable />
            </Search.Provider>
          </div>
        </>
      )}
    </div>
  );
}

const applyFilters = (
  data: EvseBulkActionWorkflow[],
  filters: Record<WorkflowFilterFields, any>
) => {
  let workflows = data;
  if (filters?.statuses) {
    workflows = workflows.filter((workflow) =>
      filters.statuses.includes(workflow.status)
    );
  }
  if (filters?.accountIds) {
    workflows = workflows.filter((workflow) =>
      filters.accountIds.includes(workflow.evseController?.accountId)
    );
  }
  if (filters?.locationIds) {
    workflows = workflows.filter((workflow) =>
      filters.locationIds.includes(workflow.evseController?.locationId)
    );
  }
  if (filters?.maintenanceAccountIds) {
    workflows = workflows.filter((workflow) =>
      filters.maintenanceAccountIds.includes(
        workflow.evseController?.maintenanceAccountId
      )
    );
  }
  if (filters?.vendors) {
    const vendors = filters.vendors.map((vendor: string) =>
      decodeStringForFilter(vendor)
    );
    workflows = workflows.filter((workflow) =>
      vendors.includes(workflow.evseController?.bootInfo?.chargePointVendor)
    );
  }
  if (filters?.firmwareVersions) {
    const firmwareVersions = filters.firmwareVersions.map(
      (firmwareVersion: string) => decodeStringForFilter(firmwareVersion)
    );
    workflows = workflows.filter((workflow) =>
      firmwareVersions.includes(
        workflow.evseController?.bootInfo?.firmwareVersion
      )
    );
  }
  if (filters?.powerTypes) {
    const powerTypes = filters.powerTypes.map((powerType: string) =>
      decodeStringForFilter(powerType)
    );
    workflows = workflows.filter((workflow) =>
      workflow.evseController?.connectors?.some((connector) =>
        powerTypes.includes(connector.powerType)
      )
    );
  }
  return workflows;
};
