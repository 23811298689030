import React from 'react';
import { TectonicProvider, TimeRangePicker } from 'react-tectonic';
import AnalyticsToken from 'components/AnalyticsToken';
import { Header, Button, Divider, Statistic, Grid, Segment } from 'semantic';
import {
  AggregateTimeSeries,
  SeriesChart,
  ErrorBoundary,
} from 'react-tectonic';

import CardinalityStatistic from 'components/analytics/CardinalityStatistic';
import TermsDonutChart from 'components/analytics/TermsDonutChart';
import TermsTable from 'components/analytics/TermsTable';
import {} from 'utils/visualizations';

import { TECTONIC_URL } from 'utils/env';
import { useUser } from 'contexts/user';

export default function LocationsAnalytics() {
  const { provider } = useUser();
  const colorHex = `#${provider.primaryColorHex}`;

  return (
    <AnalyticsToken path="/1/users/me/analytics-tokens/msp-locations">
      <TectonicProvider
        debug
        baseUrl={TECTONIC_URL}
        primaryColor="#ff3d00"
        collection="locations"
        dateField="createdAt"
        timeRangeMode="all">
        <React.Fragment>
          <div style={{ float: 'right' }}>
            <TimeRangePicker
              align="right"
              renderButton={(label, handleOnClick) => (
                <Button
                  primary
                  icon="clock"
                  content={label}
                  onClick={handleOnClick}
                />
              )}
            />
          </div>
          <Header as="h2">Locations Analytics</Header>
          <div style={{ position: 'relative' }}>
            <ErrorBoundary>
              <Divider hidden />
              <Divider hidden />

              <Statistic.Group widths="three">
                <CardinalityStatistic
                  collection="locations"
                  label="Locations"
                />
                <CardinalityStatistic
                  collection="locations"
                  label="EVSEs"
                  field="evses.evse_id"
                />
                <CardinalityStatistic
                  collection="locations"
                  label="Countries"
                  field="country"
                />
              </Statistic.Group>
              <Divider hidden />
              <Divider hidden />

              <AggregateTimeSeries collection="locations" operation="count">
                <SeriesChart
                  title="Locations"
                  titleAlign="center"
                  chartType="bar"
                  height={350}
                  valueField="count"
                  valueFieldLabel={'New Locations'}
                  color={colorHex}
                />
              </AggregateTimeSeries>

              <Divider hidden />
              <Divider hidden />

              <Grid>
                <Grid.Row>
                  <Grid.Column width={8}>
                    <Segment basic>
                      <TermsTable
                        collection="locations"
                        aggField="infraProviderId"
                        title="External Providers"
                        operation="count"
                        valueField="count"
                        valueFieldName="Locations"
                        labelFormatter={(v) => v.toString().toUpperCase()}
                        termsSize={15}
                      />
                    </Segment>
                  </Grid.Column>
                  <Grid.Column width={8}>
                    <Segment basic>
                      <TermsDonutChart
                        collection="locations"
                        aggField="source"
                        title="Source"
                        valueFieldName="Source"
                        termsSize={15}
                      />
                    </Segment>
                  </Grid.Column>
                </Grid.Row>
              </Grid>

              <Divider hidden />
              <Divider hidden />

              <Grid>
                <Grid.Row>
                  <Grid.Column width={8}>
                    <Segment basic>
                      <TermsDonutChart
                        collection="locations"
                        aggField="country"
                        operation="count"
                        title="Countries"
                        labelFormatter={(v) => v.toString().toUpperCase()}
                        termsSize={15}
                      />
                    </Segment>
                  </Grid.Column>
                  <Grid.Column width={8}>
                    <Segment basic>
                      <TermsTable
                        collection="locations"
                        aggField="city"
                        operation="count"
                        title="Cities"
                        valueField="count"
                        valueFieldName="Locations"
                        termsSize={15}
                      />
                    </Segment>
                  </Grid.Column>
                </Grid.Row>
              </Grid>

              <Divider hidden />
              <Divider hidden />
            </ErrorBoundary>
          </div>
        </React.Fragment>
      </TectonicProvider>
    </AnalyticsToken>
  );
}
