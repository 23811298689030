import React, { useContext } from 'react';

import { Table } from 'semantic';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Search } from 'components';

import { EvseControllerBulkActionExecution } from 'types/evse-controller-bulk-action-execution';
import { formatDateTime } from 'utils/date';
import EvseControllerBulkActionExecutionStatus from '../EvseControllerBulkActionExecutionStatus';
import {
  EVSE_CONTROLLERS_BACKGROUND_JOBS_FE_PATH,
  USERS_FE_PATH,
} from '../utils';

export default function TableView() {
  const { items, loading } = useContext(Search.Context);
  const { t } = useTranslation();

  if (!items.length || loading) return null;

  const jobs: EvseControllerBulkActionExecution[] = items;

  return (
    <>
      <Table celled>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell width={2}>
              {t('evseControllersBackgroundJobs.columnJobId', 'Job Id')}
            </Table.HeaderCell>
            <Table.HeaderCell width={2}>
              {t('evseControllersBackgroundJobs.columnJobType', 'Job Type')}
            </Table.HeaderCell>
            <Table.HeaderCell width={2}>
              {t(
                'evseControllersBackgroundJobs.columnNumberOfItems',
                'No. Of Items'
              )}
            </Table.HeaderCell>
            <Table.HeaderCell width={2}>
              {t('evseControllersBackgroundJobs.columnStartedAt', 'Started At')}
            </Table.HeaderCell>
            <Table.HeaderCell width={2}>
              {t('evseControllersBackgroundJobs.columnEndedAt', 'Ended At')}
            </Table.HeaderCell>
            <Table.HeaderCell width={2}>
              {t('evseControllersBackgroundJobs.columnNote', 'Note')}
            </Table.HeaderCell>
            <Table.HeaderCell width={2}>
              {t('evseControllersBackgroundJobs.columnCreatedBy', 'Created By')}
            </Table.HeaderCell>
            <Table.HeaderCell width={2}>
              {t('evseControllersBackgroundJobs.columnStatis', 'Status')}
            </Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {jobs.map((item) => {
            return (
              <Table.Row key={item.id}>
                <Table.Cell>
                  <Link
                    to={`${EVSE_CONTROLLERS_BACKGROUND_JOBS_FE_PATH}/${item.id}`}>
                    {item.id}
                  </Link>
                </Table.Cell>
                <Table.Cell>{item.actionType}</Table.Cell>
                <Table.Cell>
                  <Link
                    to={`${EVSE_CONTROLLERS_BACKGROUND_JOBS_FE_PATH}/${item.id}`}
                    target="_blank">
                    {item.workflowExecutionsNumber}
                  </Link>
                </Table.Cell>
                <Table.Cell>{formatDateTime(item.startedAt)}</Table.Cell>
                <Table.Cell>
                  {item.endedAt ? formatDateTime(item.endedAt) : '-'}
                </Table.Cell>
                <Table.Cell>{item.note}</Table.Cell>
                <Table.Cell>
                  {item.creatorUser && (
                    <Link
                      to={`${USERS_FE_PATH}/${item.creatorUser.id}`}
                      target="_blank">
                      {item.creatorUser.contact?.firstName}{' '}
                      {item.creatorUser.contact?.lastName}
                    </Link>
                  )}
                </Table.Cell>
                <Table.Cell>
                  <EvseControllerBulkActionExecutionStatus
                    status={item.status}
                  />
                </Table.Cell>
              </Table.Row>
            );
          })}
        </Table.Body>
      </Table>
    </>
  );
}
