import React from 'react';
import { Divider, Segment, Statistic, Grid } from 'semantic';
import {
  Aggregate,
  AggregateTimeSeries,
  AggregateTimeMap,
  AggregateTerms,
  SeriesChart,
  MultiSeriesChart,
  PunchChart,
} from 'react-tectonic';

import { getColors } from 'utils/visualizations';
import CardinalityStatistic from 'components/analytics/CardinalityStatistic';
import TermsDonutChart from 'components/analytics/TermsDonutChart';
import TermsTable from 'components/analytics/TermsTable';
import MultiCardinalityDonutChart from 'components/analytics/MultiCardinalityDonutChart';
import { Link } from 'react-router-dom';
import Breadcrumbs from 'components/Breadcrumbs';
import { useUser } from 'contexts/user';

export default function AccountAnalytics() {
  const { provider } = useUser();

  const colorHex = `#${provider.primaryColorHex}`;
  const colors = getColors(colorHex);

  return (
    <div>
      <Breadcrumbs active="Analytics" />
      <Divider hidden />
      <Divider hidden />

      <Statistic.Group widths="two">
        <CardinalityStatistic collection="users" label="Users" />
        <CardinalityStatistic collection="accounts" label="Accounts" />
      </Statistic.Group>

      <Divider hidden />
      <Divider hidden />

      <AggregateTerms
        collection="accounts"
        aggField="type"
        operation="count"
        termsSize={10}>
        {({ data }) => {
          return (
            <Aggregate
              type="time-series"
              collection="accounts"
              requests={data.map((term) => {
                return {
                  operation: 'count',
                  dateField: 'createdAt',
                  filter: {
                    terms: [{ type: term.key }],
                  },
                };
              })}>
              <MultiSeriesChart
                title={'Accounts and Users Created'}
                colors={colors}
                titleAlign="center"
                height={350}
                chartType="area"
                stacked
                valueField="count"
                labels={data.map((term) => term.key)}
              />
            </Aggregate>
          );
        }}
      </AggregateTerms>

      <Divider hidden />
      <Divider hidden />

      <AggregateTimeSeries collection="accounts" operation="count">
        <SeriesChart
          titleAlign="center"
          title={'Accounts Created'}
          chartType="area"
          height={350}
          valueField="count"
          valueFieldLabel="Accounts"
          color={colors[0]}
        />
      </AggregateTimeSeries>

      <Divider hidden />
      <Divider hidden />

      <Grid>
        <Grid.Row>
          <Grid.Column width={8}>
            <Segment basic>
              <TermsDonutChart
                colors={colors}
                collection="accounts"
                aggField="type"
                title="Account Type"
              />
            </Segment>
          </Grid.Column>
          <Grid.Column width={8}>
            <Segment basic>
              <MultiCardinalityDonutChart
                collection="users"
                title="Signup Completed"
                colors={colors}
                items={[
                  {
                    label: 'Completed',
                    request: {
                      collection: 'users',
                      fields: ['id'],
                      filter: {
                        terms: [{ signupCompleted: true }],
                      },
                    },
                  },
                  {
                    label: 'Not Completed',
                    request: {
                      collection: 'users',
                      fields: ['id'],
                      filter: {
                        terms: [{ signupCompleted: false }],
                      },
                    },
                  },
                ]}
              />
            </Segment>
          </Grid.Column>
        </Grid.Row>
      </Grid>

      <Divider hidden />
      <Divider hidden />

      <Grid>
        <Grid.Row>
          <Grid.Column width={8}>
            <Segment basic>
              <TermsTable
                collection="users"
                aggField="referral"
                title="Referrals"
                valueField="count"
                valueFieldName="Users"
                termsSize={15}
              />
            </Segment>
          </Grid.Column>
          <Grid.Column width={8}>
            <Segment basic>
              <MultiCardinalityDonutChart
                collection="users"
                colors={colors}
                title="Signup from Referral"
                items={[
                  {
                    label: 'Referral',
                    request: {
                      collection: 'users',
                      fields: ['id'],
                      filter: {
                        exists: 'referral',
                      },
                    },
                  },
                  {
                    label: 'None',
                    request: {
                      collection: 'users',
                      fields: ['id'],
                      filter: {
                        notExists: 'referral',
                      },
                    },
                  },
                ]}
              />
            </Segment>
          </Grid.Column>
        </Grid.Row>
      </Grid>

      <Divider hidden />
      <Divider hidden />

      <Grid>
        <Grid.Row>
          <Grid.Column width={8}>
            <Segment basic>
              <MultiCardinalityDonutChart
                title="Newsletter Signup"
                collection="users"
                colors={colors}
                items={[
                  {
                    label: 'Opted In',
                    request: {
                      collection: 'users',
                      fields: ['id'],
                      filter: {
                        terms: [{ newsletter: true }],
                      },
                    },
                  },
                  {
                    label: 'Opted out',
                    request: {
                      collection: 'users',
                      fields: ['id'],
                      filter: {
                        terms: [{ newsletter: false }],
                      },
                    },
                  },
                ]}
              />
            </Segment>
          </Grid.Column>
          <Grid.Column width={8}>
            <Segment basic>
              <MultiCardinalityDonutChart
                title="Accounts Deleted"
                collection="accounts"
                colors={colors}
                items={[
                  {
                    label: 'Not Deleted',
                    request: {
                      collection: 'accounts',
                      fields: ['id'],
                      filter: {
                        notExists: 'deletedAt',
                      },
                    },
                  },
                  {
                    label: 'Deleted',
                    request: {
                      collection: 'accounts',
                      fields: ['id'],
                      filter: {
                        exists: 'deletedAt',
                      },
                    },
                  },
                ]}
              />
            </Segment>
          </Grid.Column>
        </Grid.Row>
      </Grid>

      <Divider hidden />
      <Divider hidden />

      <AggregateTimeMap collection="accounts" dateField="createdAt">
        <PunchChart
          titleAlign="center"
          title={'Accounts Creation Time Map'}
          height={490}
          color={colors[0]}
        />
      </AggregateTimeMap>

      <Divider hidden />
      <Divider hidden />

      <Grid>
        <Grid.Row>
          <Grid.Column width={8}>
            <Segment basic>
              <TermsTable
                collection="users"
                aggField="accountId"
                operation="count"
                title="Top Accounts by Users"
                valueField="count"
                valueFieldName="Users"
                fetchReference="accounts"
                filter={{ terms: [{ deleted: false }] }}
                referenceLabelFormatter={(item) => {
                  return <Link to={`/accounts/${item.id}`}>{item.name}</Link>;
                }}
                termsSize={20}
              />
            </Segment>
          </Grid.Column>
          <Grid.Column width={8}>
            <Segment basic>
              <TermsDonutChart
                colors={colors}
                collection="accounts"
                aggField="defaultLangCode"
                title="User Languages"
              />
            </Segment>
          </Grid.Column>
        </Grid.Row>
      </Grid>

      <Divider hidden />
      <Divider hidden />

      <Grid>
        <Grid.Row>
          <Grid.Column width={8}>
            <Segment basic>
              <TermsDonutChart
                colors={colors}
                collection="accounts"
                aggField="billing.countryCode"
                title="Billing Countries"
              />
            </Segment>
          </Grid.Column>
          <Grid.Column width={8}>
            <Segment basic>
              <TermsTable
                collection="accounts"
                aggField="billing.city"
                operation="count"
                title="Billing Cities"
                valueField="count"
                valueFieldName="Accounts"
                termsSize={20}
              />
            </Segment>
          </Grid.Column>
        </Grid.Row>
      </Grid>

      <Divider hidden />
      <Divider hidden />

      <Grid>
        <Grid.Row>
          <Grid.Column width={16}>
            <Segment basic>
              <TermsTable
                height={200}
                collection="users"
                title="Roles"
                aggField="accountRoles.roleId"
                operation="count"
                fetchReference="roles"
                valueField="count"
                valueFieldName="Amount"
                termsSize={5}
              />
            </Segment>
          </Grid.Column>
        </Grid.Row>
      </Grid>

      <Divider hidden />
      <Divider hidden />
    </div>
  );
}
