import { EvseController } from './evse-controller';

export enum EvseControllerBulkActionType {
  UpdateFirmware = 'UpdateFirmware',
  ExecuteCommand = 'ExecuteCommand',
}

export enum EvseControllerBulkActionStatus {
  InProgress = 'InProgress',
  PartlyCompleted = 'PartlyCompleted',
  Completed = 'Completed',
  Failed = 'Failed',
}

export type EvseControllerBulkActionExecutionCreatorUser = {
  id: string;
  contact?: {
    firstName?: string;
    lastName?: string;
  };
};

export interface EvseBulkActionWorkflow {
  executionId: string;
  chargingStationId: string;
  bulkExecutionId: string;
  status: EvseControllerBulkActionStatus;
  statusDetails: string;
  startedAt: Date;
  endedAt?: Date;
  evseController?: EvseController;
}

export type EvseControllerBulkActionExecution = {
  id: string;
  bulkExecutionId: string;
  createdAt: Date;
  updatedAt: Date;
  startedAt: Date;
  endedAt?: Date;
  creatorUser: EvseControllerBulkActionExecutionCreatorUser;
  creatorAccountId: string;
  creatorProviderId: string;
  actionType: EvseControllerBulkActionType;
  parameters: object;
  description: string;
  note: string;
  status: EvseControllerBulkActionStatus;
  workflowExecutionsNumber: number;
  workflows?: EvseBulkActionWorkflow[];
};
