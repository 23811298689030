import { Grid, Segment } from 'semantic-ui-react';
import { ActionButton, Button, Divider, Form, Icon } from 'semantic';
import { Help, InputField } from 'components/config/ConfigField';
import React, { useState } from 'react';
import { shortCircuitEvent } from 'utils/events';
import {
  StandardConfigurationKey,
  StandardConfigurationKeys,
} from './protocol';
import { useTranslation } from 'react-i18next';
import { oc } from 'date-fns/locale';
import { DropdownItemProps } from 'semantic-ui-react/dist/commonjs/modules/Dropdown/DropdownItem';

export const CONFIGURATION_INPUT_TYPE_STANDARD = 'standard';
export const CONFIGURATION_INPUT_TYPE_CUSTOM = 'custom';

export type ConfigurationEntry = {
  name: string;
  value: string;
  component?: string;
  ocppProtocol: string;
  label: string;
  input: 'standard' | 'custom';
  standardKeyId?: string;
};

type StandardConfigurationInput = {
  key?: StandardConfigurationKey;
  value?: string;
};

type CustomConfigurationInput = {
  key?: string;
  component?: string;
  value?: string;
};

export default function ConfigurationInput({
  standardConfigurationKeys,
  ocppProtocols,
  onSave,
  addText,
  addIcon = 'plus',
  inputType = CONFIGURATION_INPUT_TYPE_STANDARD,
  standardConfigurationValue,
  customConfigurationValue,
}: {
  standardConfigurationKeys: StandardConfigurationKeys;
  ocppProtocols: string[];
  onSave: (entry: ConfigurationEntry) => void;
  addText: string;
  addIcon?: string;
  inputType?: string;
  standardConfigurationValue?: StandardConfigurationInput;
  customConfigurationValue?: CustomConfigurationInput;
}) {
  const { t } = useTranslation();

  const [standardConfiguration, setStandardConfiguration] = useState<
    StandardConfigurationInput | undefined
  >(standardConfigurationValue);

  const [customConfiguration, setCustomConfiguration] = useState<
    CustomConfigurationInput | undefined
  >(customConfigurationValue);

  const [configurationInputType, setConfigurationInputType] =
    useState(inputType);

  return (
    <Segment>
      <Grid style={{ marginBottom: 6 }}>
        <Grid.Row columns={4} style={{ paddingBottom: 0 }}>
          <Grid.Column>
            <SelectConfigurationInputTypeButton
              selected={
                configurationInputType === CONFIGURATION_INPUT_TYPE_STANDARD
              }
              text={t(
                'evseControllersBackgroundJobsChangeConfiguration.standardConfigurationSelectorLabel',
                'Standard Configuration'
              )}
              onClick={() => {
                setConfigurationInputType(CONFIGURATION_INPUT_TYPE_STANDARD);
              }}
            />
          </Grid.Column>
          <Grid.Column>
            <SelectConfigurationInputTypeButton
              selected={
                configurationInputType === CONFIGURATION_INPUT_TYPE_CUSTOM
              }
              text={t(
                'evseControllersBackgroundJobsChangeConfiguration.customConfigurationSelectorLabel',
                'Custom Configuration'
              )}
              onClick={() => {
                setConfigurationInputType(CONFIGURATION_INPUT_TYPE_CUSTOM);
                setStandardConfiguration(undefined);
              }}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>

      <Divider hidden />

      {configurationInputType === CONFIGURATION_INPUT_TYPE_STANDARD && (
        <>
          <Form.Dropdown
            style={{ width: '50%' }}
            label={t(
              'evseControllersBackgroundJobsChangeConfiguration.standardVariableLabel',
              'Standard Variable (Key)'
            )}
            selection
            search={(
              options: DropdownItemProps[],
              value: string
            ): DropdownItemProps[] => {
              const searchTerm = value.toLowerCase();
              return options.filter((option) =>
                option.value?.toString()?.toLowerCase()?.includes(searchTerm)
              );
            }}
            value={standardConfiguration?.key?.id || ''}
            options={Object.values(standardConfigurationKeys || {}).map(
              (value) => ({
                key: value.id,
                value: value.id,
                text: (
                  <>
                    {value.label}
                    {ocppProtocols.length > 1 && (
                      <span style={{ color: 'grey' }}>
                        {' '}
                        OCPP{' '}
                        {value.ocppProtocol === 'ocpp1.6' ? '1.6' : '2.0.1'}
                      </span>
                    )}
                  </>
                ),
              })
            )}
            id="variable-name"
            name="variable-name"
            onChange={(e, { value }) => {
              setStandardConfiguration({
                ...standardConfiguration,
                key: standardConfigurationKeys[value as string],
                value: undefined,
              });
            }}
          />

          {standardConfiguration?.key && (
            <Form.Field style={{ width: '50%' }}>
              <label>
                {t(
                  'evseControllersBackgroundJobsChangeConfiguration.standardVariableValueLabel',
                  'Value'
                )}
                <Help
                  title={standardConfiguration.key.label}
                  configInfo={standardConfiguration.key.info}
                />
              </label>
              <InputField
                configKey={standardConfiguration.key.id}
                value={standardConfiguration.value || ''}
                handleChange={(
                  e: React.SyntheticEvent<HTMLElement>,
                  { value }: { value: string }
                ) =>
                  setStandardConfiguration({
                    ...standardConfiguration,
                    value,
                  })
                }
                configInfo={standardConfiguration.key.info}
                setFieldValue={(key: string, value: string) =>
                  setStandardConfiguration({
                    ...standardConfiguration,
                    value,
                  })
                }
              />
            </Form.Field>
          )}
        </>
      )}

      {configurationInputType === CONFIGURATION_INPUT_TYPE_CUSTOM && (
        <>
          <Form.Input
            style={{ width: '50%' }}
            name="custom-variable"
            placeholder={t(
              'evseControllersBackgroundJobsChangeConfiguration.customVariablePlaceholder',
              'Type here...'
            )}
            label={t(
              'evseControllersBackgroundJobsChangeConfiguration.customVariableLabel',
              'Custom Variable (Key) Name'
            )}
            type="text"
            value={customConfiguration?.key || ''}
            onChange={(e, { value }) =>
              setCustomConfiguration({
                ...customConfiguration,
                key: value,
              })
            }
          />
          {ocppProtocols.includes('ocpp2.0.1') && (
            <Form.Input
              style={{ width: '50%' }}
              name="custom-variable-component"
              placeholder={t(
                'evseControllersBackgroundJobsChangeConfiguration.customVariableComponentNamePlaceholder',
                'Type here...'
              )}
              label={
                ocppProtocols.length > 1
                  ? t(
                      'evseControllersBackgroundJobsChangeConfiguration.customVariableComponentNameMixedProtocolsLabel',
                      'Component Name (only OCPP 2.0.1)'
                    )
                  : t(
                      'evseControllersBackgroundJobsChangeConfiguration.customVariableComponentNameLabel',
                      'Component Name'
                    )
              }
              type="text"
              value={customConfiguration?.component || ''}
              onChange={(e, { value }) =>
                setCustomConfiguration({
                  ...customConfiguration,
                  component: value,
                })
              }
            />
          )}
          <Form.Input
            style={{ width: '50%' }}
            name="custom-variable-value"
            placeholder={t(
              'evseControllersBackgroundJobsChangeConfiguration.customVariableValuePlaceholder',
              'Type here...'
            )}
            label={t(
              'evseControllersBackgroundJobsChangeConfiguration.customVariableValueLabel',
              'Value'
            )}
            type="text"
            value={customConfiguration?.value || ''}
            onChange={(e, { value }) =>
              setCustomConfiguration({
                ...customConfiguration,
                value,
              })
            }
          />
        </>
      )}

      <AddConfigurationActionButton
        text={addText}
        icon={addIcon}
        disabled={
          (configurationInputType === CONFIGURATION_INPUT_TYPE_STANDARD &&
            (standardConfiguration?.key === undefined ||
              standardConfiguration?.value === undefined)) ||
          (configurationInputType === CONFIGURATION_INPUT_TYPE_CUSTOM &&
            (customConfiguration?.key === undefined ||
              (customConfiguration?.component === undefined &&
                ocppProtocols.length === 1 &&
                ocppProtocols[0] === 'ocpp2.0.1') ||
              customConfiguration?.value === undefined))
        }
        onClick={() => {
          let entry: ConfigurationEntry | undefined;
          if (
            configurationInputType === CONFIGURATION_INPUT_TYPE_STANDARD &&
            standardConfiguration?.key &&
            standardConfiguration?.value
          ) {
            entry = {
              name: standardConfiguration.key.name,
              value: standardConfiguration.value,
              input: configurationInputType,
              ocppProtocol: standardConfiguration.key.ocppProtocol,
              label: standardConfiguration.key.label,
              component:
                'component' in standardConfiguration.key.info
                  ? standardConfiguration?.key.info.component
                  : undefined,
              standardKeyId: standardConfiguration.key.id,
            };
          } else if (
            configurationInputType === CONFIGURATION_INPUT_TYPE_CUSTOM &&
            customConfiguration &&
            customConfiguration.key !== undefined &&
            customConfiguration.value !== undefined
          ) {
            entry = {
              name: customConfiguration.key,
              value: customConfiguration.value,
              input: configurationInputType,
              label: customConfiguration.key,
              ocppProtocol: 'ocpp1.6',
              ...(customConfiguration.component && {
                ocppProtocol: 'ocpp2.0.1',
                label: `${customConfiguration.component}.${customConfiguration.key}`,
                component: customConfiguration.component,
              }),
            };
          }
          if (entry) {
            onSave(entry);
            setStandardConfiguration(undefined);
            setCustomConfiguration(undefined);
          }
        }}
      />
    </Segment>
  );
}

function AddConfigurationActionButton({
  text,
  icon,
  disabled,
  onClick,
}: {
  text: string;
  icon: string;
  disabled: boolean;
  onClick: () => void;
}) {
  return (
    <ActionButton
      style={{ paddingLeft: 0 }}
      disabled={disabled}
      primary
      compact
      onClick={(e: React.SyntheticEvent<HTMLElement>) => {
        shortCircuitEvent(e);
        onClick?.();
      }}>
      <>
        <Icon name={icon} />
        {text}
      </>
    </ActionButton>
  );
}

function SelectConfigurationInputTypeButton({
  text,
  selected,
  onClick,
}: {
  text: string;
  selected: boolean;
  onClick: (selected: boolean) => void;
}) {
  return (
    <Button
      basic
      color={selected ? 'blue' : null}
      style={{ width: '100%' }}
      onClick={(e: React.SyntheticEvent<HTMLElement>) => {
        shortCircuitEvent(e);
        onClick?.(true);
      }}>
      <div style={{ float: 'left', fontWeight: 'bold' }}>{text}</div>
      <div style={{ float: 'right' }}>
        {selected && <Icon name={'circle-check'} />}
        {!selected && <Icon name={'circle regular'} />}
      </div>
    </Button>
  );
}
